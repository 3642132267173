import * as React from 'react';
import { Box, Typography, Button, IconButton, Stack, Grid } from '@mui/material';
import { Facebook, GitHub, Instagram } from '@mui/icons-material';
import { NavLink } from 'react-router-dom'; // Import NavLink for routing

const logoStyle = {
  width: '290px', // Adjusted size for a modern look
  height: 'auto',
};

function Copyright() {
  return (
    <Typography variant="body2" sx={{ color: 'gray', textAlign: 'center' }}>
      {'Copyright © '}
      <Button
        component="a"
        href="https://itvisionsoftware.ro"
        sx={{ color: 'inherit', textDecoration: 'none' }}
      >
        IT VISION SOFTWARE SRL
      </Button>{' '}
      {new Date().getFullYear()}
    </Typography>
  );
}

export default function Footer() {
  return (
    <Box
      id="footerid"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        py: { xs: 6, sm: 8 },
        width: '100%',
        backgroundColor: '#121212', // Darker background for a modern feel
        color: 'white',
      }}
    >
      <Box
        sx={{
          mb: 4,
          textAlign: 'center',
        }}
      >
        <img
          src='./logo-ivs.png'
          style={logoStyle}
          alt="IT VISION SOFTWARE"
        />
      </Box>

      <Grid container spacing={4} sx={{ width: { xs: '90%', sm: '80%' }, mb: 4 }}>
      <Grid item xs={12} sm={6} sx={{ textAlign: 'center' }}>
  <Typography variant="h6" fontWeight={600} mb={2}>
    Navigation
  </Typography>
  {['Acasa', 'Servicii Software', 'Servicii Web', 'Contact', 'Terms And Conditions'].map((text, index) => (
    <Button
      key={text}
      component={NavLink}
      to={
        index === 0 ? '/acasa' :
        index === 1 ? '/servicii-software' :
        index === 2 ? '/servicii-web' :
        index === 3 ? '/contact' :
        index === 4 ? '/TermsAndConditions' :
        '/contact'  // Default route for index 3
      }
      sx={{
        color: 'white',
        display: 'block',
        mb: 1,
        textTransform: 'none',
        transition: 'color 0.3s',
        '&:hover': { color: 'dodgerblue', backgroundColor: 'transparent' }
      }}
    >
      {text}
    </Button>
  ))}
</Grid>

        <Grid item xs={12} sm={6} sx={{ textAlign: 'center' }}>
          <Typography variant="h6" fontWeight={600} mb={2}>
            Legal
          </Typography>
          <Button
            component="a"
            href="https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home.chooseLanguage"
            target="_blank"
            sx={{
              color: 'white',
              display: 'block',
              mb: 1,
              textTransform: 'none',
              transition: 'color 0.3s',
              '&:hover': { color: 'dodgerblue', backgroundColor: 'transparent' }
            }}
          >
            <img
              src='imagini/anpc.png'
              alt="ANPC"
              style={{ width: '100%', maxWidth: '300px', height: 'auto' }} // Responsive image size
            />
          </Button>
          <Button
            component="a"
            href="https://anpc.ro/ce-este-sal/"
            target="_blank"
            sx={{
              color: 'white',
              display: 'block',
              mb: 1,
              textTransform: 'none',
              transition: 'color 0.3s',
              '&:hover': { color: 'dodgerblue', backgroundColor: 'transparent' }
            }}
          >
            <img
              src='imagini/anpc2.png'
              alt="ANPC2"
              style={{ width: '100%', maxWidth: '300px', height: 'auto' }} // Responsive image size
            />
          </Button>
        </Grid>
      </Grid>

      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' },
          justifyContent: 'space-between', // Distribute items evenly
          alignItems: 'center',
          width: { xs: '90%', sm: '80%' },
          borderTop: '1px solid gray',
          pt: 3,
          textAlign: 'center', // Ensure text is centered
        }}
      >
        <Box sx={{ mb: { xs: 2, sm: 0 } }}>
          <Copyright />
        </Box>
        <Stack
          direction="row"
          spacing={2}
          sx={{ mt: { xs: 2, sm: 0 } }}
        >
          <IconButton
            color="inherit"
            href="https://www.facebook.com/people/It-Vision-Software/61556909073114/"
            aria-label="Facebook"
            sx={{ color: 'dodgerblue', transition: 'color 0.3s', '&:hover': { color: 'white' } }}
          >
            <Facebook />
          </IconButton>
          <IconButton
            color="inherit"
            href="https://www.instagram.com/ivs.development/"
            aria-label="Instagram"
            sx={{ color: 'dodgerblue', transition: 'color 0.3s', '&:hover': { color: 'white' } }}
          >
            <Instagram />
          </IconButton>
          <IconButton
            color="inherit"
            href="#"
            aria-label="GitHub"
            sx={{ color: 'dodgerblue', transition: 'color 0.3s', '&:hover': { color: 'white' } }}
          >
            <GitHub />
          </IconButton>
        </Stack>
      </Box>
    </Box>
  );
}
