import React, { useEffect, useState, useCallback } from 'react';
import { CircularProgress, IconButton, Tooltip } from '@mui/material';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { styled } from '@mui/system';
import gsap from 'gsap';
import ScrollToPlugin from 'gsap/ScrollToPlugin';

// Register the ScrollToPlugin
gsap.registerPlugin(ScrollToPlugin);

// Styled button with modern look
const BackToTopButton = styled(IconButton)(({ theme }) => ({
  position: 'fixed',
  bottom: 20,
  right: 20,
  zIndex: 1000,
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
  borderRadius: '50%',
  width: 56,
  height: 56,
  boxShadow: `0 4px 8px rgba(0, 0, 0, 0.3)`,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  opacity: 0, // Start hidden
  transform: 'scale(0.8)',
  transition: 'box-shadow 0.3s',
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
    transform: 'scale(1.1)',
    boxShadow: `0 6px 12px rgba(0, 0, 0, 0.4)`,
  },
  '&:active': {
    transform: 'scale(1)',
    boxShadow: `0 3px 6px rgba(0, 0, 0, 0.2)`,
    transition: 'transform 0.1s, box-shadow 0.1s',
  },
}));

// Styled CircularProgress with custom size and color
const Progress = styled(CircularProgress)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  color: 'rgba(0,0,0,0.6)',
  zIndex: 1,
}));

const BackToTop: React.FC = () => {
  const [scrolling, setScrolling] = useState(false);
  const [scrollProgress, setScrollProgress] = useState(0);
  const [tooltipOpen, setTooltipOpen] = useState(false);

  // Handle scroll and update state
  const handleScroll = useCallback(() => {
    const scrollTop = window.scrollY;
    const docHeight = document.documentElement.scrollHeight;
    const winHeight = window.innerHeight;
    const scrollPercent = (scrollTop / (docHeight - winHeight)) * 100;

    setScrollProgress(scrollPercent);
    setScrolling(scrollTop > 300);
  }, []);

  // Attach scroll event listener
  useEffect(() => {
    const handleScrollEvent = () => {
      handleScroll();
    };

    // Using `throttle` to limit how often handleScroll is called
    const throttleScroll = throttle(handleScrollEvent, 50); // Adjust delay for better performance

    window.addEventListener('scroll', throttleScroll);

    return () => window.removeEventListener('scroll', throttleScroll);
  }, [handleScroll]);

  // GSAP animation for entering and leaving
  useEffect(() => {
    const button = document.querySelector('.back-to-top-button');
    if (button) {
      if (scrolling) {
        gsap.to(button, {
          opacity: 1,
          scale: 1,
          duration: 0.3,
          ease: 'power2.out',
        });
      } else {
        gsap.to(button, {
          opacity: 0,
          scale: 0.8,
          duration: 0.3,
          ease: 'power2.in',
        });
      }
    }
  }, [scrolling]);

  // Scroll to top
  const scrollToTop = () => {
    gsap.to(window, { scrollTo: 0, duration: 0.6 });
    setTooltipOpen(false); // Hide tooltip on click
  };

  // Throttle function to limit the rate of function calls
  function throttle(func: (...args: any[]) => void, limit: number): (...args: any[]) => void {
    let lastFunc: ReturnType<typeof setTimeout> | undefined;
    let lastRan: number | undefined;
    return function(this: any, ...args: any[]) {
      const context = this;
      if (lastRan === undefined) {
        func.apply(context, args);
        lastRan = Date.now();
      } else {
        clearTimeout(lastFunc);
        lastFunc = setTimeout(function() {
          if ((Date.now() - lastRan!) >= limit) {
            func.apply(context, args);
            lastRan = Date.now();
          }
        }, limit - (Date.now() - lastRan!));
      }
    };
  }

  return (
    <Tooltip
      title="Back to Top"
      aria-label="Back to Top"
      open={tooltipOpen && scrolling} // Show tooltip only when scrolling and open
      onClose={() => setTooltipOpen(false)} // Handle tooltip close
      disableFocusListener // Do not hide on focus
      disableHoverListener // Hide on click
      disableTouchListener // Do not hide on touch
    >
      <BackToTopButton
        onClick={scrollToTop}
        onMouseEnter={() => setTooltipOpen(true)} // Show tooltip on hover
        onMouseLeave={() => setTooltipOpen(false)} // Hide tooltip on mouse leave
        className="back-to-top-button"
        aria-label="Back to top"
      >
        <Progress
          size={56}
          thickness={4}
          variant="determinate"
          value={scrollProgress}
        />
        <ArrowUpwardIcon sx={{ position: 'relative', zIndex: 2 }} />
      </BackToTopButton>
    </Tooltip>
  );
};

export default BackToTop;
