import React, { useState, useEffect, useCallback } from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  useMediaQuery,
  useTheme,
  Drawer,
  Tabs,
  Tab,
  Box,
  Menu,
  MenuItem,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  ThemeProvider,
  createTheme,
  Divider,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SignInSide from './SignInSide';

const themeWithTransitions = createTheme({
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          transition: 'background-color 0.3s, height 0.3s, box-shadow 0.3s',
          boxShadow: 'none',
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          transition: 'width 0.3s',
          width: 320,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          fontWeight: '500',
          fontSize: '0.875rem',
          textTransform: 'none',
          minWidth: 120,
          padding: '12px 16px',
          '&.Mui-selected': {
            color: '#1976d2', // Primary color
            fontWeight: 'bold',
          },
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          '&:before': {
            display: 'none',
          },
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          minHeight: 48,
          '&.Mui-expanded': {
            minHeight: 48,
          },
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          padding: '8px 16px',
        },
      },
    },
  },
});

const Navbar: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [scrolling, setScrolling] = useState(false);
  const [mainDrawerOpen, setMainDrawerOpen] = useState(false);
  const [signInDrawerOpen, setSignInDrawerOpen] = useState(false);
  const [tabValue, setTabValue] = useState('/');
  const [accordionOpen, setAccordionOpen] = useState(false);
  const [serviciiMenuAnchor, setServiciiMenuAnchor] = useState<null | HTMLElement>(null);
  const [isServiciiMenuOpen, setIsServiciiMenuOpen] = useState(false);

  const location = useLocation();

  useEffect(() => {
    const handleScroll = () => {
      setScrolling(window.scrollY > 50);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    const currentPath = location.pathname;
    if (['/servicii-software', '/servicii-web'].includes(currentPath)) {
      setTabValue('/servicii');
    } else if (
      ['/acasa', '/contact', '/servicii', '/cloud', '/signin'].includes(currentPath)
    ) {
      setTabValue(currentPath);
    } else {
      setTabValue('');
    }
  }, [location.pathname]);

  const handleTabChange = useCallback((event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue);
    if (isMobile) {
      setMainDrawerOpen(false); // Close main drawer on tab change
    }
  }, [isMobile]);

  const handleMainDrawerOpen = useCallback(() => {
    setMainDrawerOpen(true);
  }, []);

  const handleMainDrawerClose = useCallback(() => {
    setMainDrawerOpen(false);
  }, []);

  const handleSignInDrawerOpen = useCallback(() => {
    setSignInDrawerOpen(true);
  }, []);

  const handleSignInDrawerClose = useCallback(() => {
    setSignInDrawerOpen(false);
  }, []);

  const handleServiciiClick = useCallback((event: React.MouseEvent<HTMLElement>) => {
    if (isMobile) {
      setAccordionOpen(prev => !prev);
    } else {
      setIsServiciiMenuOpen(prev => !prev);
      setServiciiMenuAnchor(event.currentTarget);
    }
  }, [isMobile]);

  const handleServiciiMenuClose = useCallback(() => {
    setIsServiciiMenuOpen(false);
    setServiciiMenuAnchor(null);
  }, []);

  return (
    <ThemeProvider theme={themeWithTransitions}>
      <AppBar
        position="fixed"
        sx={{
          backgroundColor: scrolling ? '#ffffff' : 'transparent',
          color: scrolling ? 'black' : '#F9F6EE',
          boxShadow: scrolling ? theme.shadows[4] : 'none',
          height: scrolling ? 56 : 72,
          zIndex: theme.zIndex.drawer + 1,
          display: mainDrawerOpen ? 'none' : 'flex', // Hide AppBar if main drawer is open
        }}
      >
        <Toolbar
          sx={{
            minHeight: scrolling ? 56 : 72,
            px: isMobile ? 1 : 2,
            color: scrolling ? 'black' : '#F9F6EE',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography
            variant="h6"
            sx={{
              display: 'flex',
              alignItems: 'center',
              fontWeight: 'bold',
              transition: 'color 0.3s ease-out',
            }}
          >
            <img
              src={scrolling ? '/logo-ivs-black.png' : '/logo-ivs.png'}
              alt="IT VISION SOFTWARE"
              style={{ maxHeight: 40, marginLeft: 10 }}
            />
          </Typography>
          {isMobile ? (
            <IconButton edge="end" aria-label="menu" onClick={handleMainDrawerOpen}>
              <MenuIcon sx={{ color: scrolling ? 'black' : '#ffffff', marginRight: 2 }} />
            </IconButton>
          ) : (
            <Box display="flex" alignItems="center">
              <Tabs
                value={tabValue}
                onChange={handleTabChange}
                aria-label="navigation tabs"
                textColor="inherit"
                indicatorColor="primary"
                sx={{ borderBottom: 1, borderColor: 'divider' }}
              >
                <Tab className="tabNavbar" component={Link} to="/acasa" label="ACASA" value="/acasa"  />
                <Tab className="tabNavbar" component={Link} to="/contact" label="CONTACT" value="/contact" />
                <Tab
                className="tabNavbar"
                  label="SERVICII"
                  value="/servicii"
                  aria-controls="servicii-menu"
                  aria-haspopup="true"
                  onClick={handleServiciiClick}
                />
                <Tab className="tabNavbar" component="a" href="https://cloud.itvisionsoftware.ro" label="CLOUD" value="/cloud" />
                <Tab  className="tabNavbar" label="CONT" onClick={handleSignInDrawerOpen} value="/signin" />
              </Tabs>
              <Menu
                id="servicii-menu"
                anchorEl={serviciiMenuAnchor}
                open={isServiciiMenuOpen}
                onClose={handleServiciiMenuClose}
                PaperProps={{
                  sx: {
                    width: 200,
                    mt: 2,
                  },
                }}
              >
                <MenuItem component={Link} to="/servicii-web" onClick={handleServiciiMenuClose}>Servicii Web</MenuItem>
                <MenuItem component={Link} to="/servicii-software" onClick={handleServiciiMenuClose}>Servicii Software</MenuItem>
              </Menu>
            </Box>
          )}
        </Toolbar>
      </AppBar>
      <Drawer
        anchor="right"
        open={mainDrawerOpen}
        onClose={handleMainDrawerClose}
        sx={{
          '& .MuiDrawer-paper': {
            width: 320,
            display: 'flex',
            flexDirection: 'column',
            padding: 2,
          },
        }}
      >
        <Box display="flex" justifyContent="flex-end" mb={2}>
          <IconButton onClick={handleMainDrawerClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Box flexGrow={1}>
          <Tabs
            orientation="vertical"
            value={tabValue}
            onChange={handleTabChange}
            aria-label="navigation tabs"
            sx={{ width: '100%', borderRight: 1, borderColor: 'divider' }}
          >
            <Tab className="tabNavbar" component={Link} to="/acasa" label="ACASA" value="/acasa" />
            <Tab className="tabNavbar" component={Link} to="/contact" label="CONTACT" value="/contact" />
            <Tab className="tabNavbar" component="a" href="https://cloud.itvisionsoftware.ro" label="CLOUD" value="/cloud" />
            <Tab  className="tabNavbar" label="CONT" onClick={handleSignInDrawerOpen} value="/signin" />
          </Tabs>
          <Box>
            <Accordion
              expanded={accordionOpen}
              onChange={() => setAccordionOpen(prev => !prev)}
              sx={{ mt: 2 }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="servicii-content"
                id="servicii-header"
              >
                <Typography fontWeight="bold">SERVICII</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box>
                  <MenuItem  component={Link} to="/servicii-web" onClick={handleMainDrawerClose}>Servicii Web</MenuItem>
                  <MenuItem component={Link} to="/servicii-software" onClick={handleMainDrawerClose}>Servicii Software</MenuItem>
                </Box>
              </AccordionDetails>
            </Accordion>
          </Box>
        </Box>
        <Divider />
      </Drawer>
      <SignInSide open={signInDrawerOpen} onClose={handleSignInDrawerClose} />
    </ThemeProvider>
  );
};

export default Navbar;
