// src/App.tsx
import React, { useState, useEffect, Suspense, lazy } from 'react';
import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Navbar from './Componente/Navbar';
import HeroSection from './Componente/HeroSection';
import LoadingScreen from './Componente/LoadingScreen';
import './App.css';
import Footer from './Componente/Footer';
import ComingSoon from './Componente/ComingSoon';
import CookieConsent from './Componente/CookieConsent';
import ServiciiSoftware from './Pagini/ServiciiSoftware';
import ServiciiWeb from './Pagini/ServiciiWeb';
import ContactPage from './Pagini/ContactPage';
import SEOHelmet from './Componente/SEOHelmet';
import TermAndConditions from './Pagini/TermsAndConitions';
import BackToTop from './Componente/BackToTop';
import { AuthProvider } from './contexts/AuthContext'; // Import AuthProvider

const Acasa = lazy(() => import('./Pagini/Acasa'));

const videos = [
  '/background3.mp4',
  '/background2.mp4',
  '/background1.mp4',
];

const Mentenanta = true;

const LocationAwareComponent = () => {
  const location = useLocation();

  return (
    <Routes location={location}>
      {Mentenanta ? (
        <>
          <Route path="/" element={<Acasa />} />
          <Route path="/acasa" element={<Acasa />} />
          <Route path="/servicii-software" element={<ComingSoon />} />
          <Route path="/servicii-web" element={<ServiciiWeb />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/TermsAndConditions" element={<TermAndConditions />} />
        </>
      ) : (
        <>
          <Route path="/" element={<Acasa />} />
          <Route path="/acasa" element={<Acasa />} />
          <Route path="/servicii-software" element={<ServiciiSoftware />} />
          <Route path="/servicii-web" element={<ServiciiWeb />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/TermsAndConditions" element={<TermAndConditions />} />
        </>
      )}
    </Routes>
  );
};

const theme = createTheme();

const App = () => {
  const [showLoading, setShowLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowLoading(false);
    }, 1400);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <AuthProvider>
          <BrowserRouter>
            <SEOHelmet />
            <Navbar />
            <HeroSection videos={videos} />
            <Suspense fallback={<LoadingScreen loading={true} />}>
              {showLoading ? (
                <LoadingScreen loading={true} />
              ) : (
                <>
                  <LocationAwareComponent />
                  <Footer />
                  <CookieConsent />
                  <BackToTop />
                </>
              )}
            </Suspense>
          </BrowserRouter>
        </AuthProvider>
      </ThemeProvider>
    </div>
  );
};

export default App;
