// src/contexts/AuthContext.tsx
import React, { createContext, useState, useEffect, ReactNode } from 'react';
import axios from 'axios';

// Define types for the context
interface AuthContextType {
  userName: string | null;
  isAuthenticated: boolean;
  login: (email: string, password: string) => Promise<void>;
  logout: () => Promise<void>;
}

// Create a context with default values
const AuthContext = createContext<AuthContextType | undefined>(undefined);

const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [userName, setUserName] = useState<string | null>(null);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);

  useEffect(() => {
    const checkSession = async () => {
      try {
        const token = localStorage.getItem('token');
        if (token) {
          const response = await axios.get('https://hub.itvisionsoftware.ro/api/user', {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            withCredentials: true,
          });

          if (response.status === 200) {
            setUserName(response.data.name);
            setIsAuthenticated(true);
          }
        }
      } catch (error) {
        console.error('Error checking session:', error);
      }
    };

    checkSession();
  }, []);

  const login = async (email: string, password: string) => {
    try {
      const response = await axios.post('https://hub.itvisionsoftware.ro/api/login', {
        email,
        password,
      }, {
        withCredentials: true,
      });
  
      if (response.status === 200) {
        localStorage.setItem('token', response.data.token);
  
        const userResponse = await axios.get('https://hub.itvisionsoftware.ro/api/user', {
          headers: {
            Authorization: `Bearer ${response.data.token}`,
          },
          withCredentials: true,
        });
  
        setUserName(userResponse.data.name);
        setIsAuthenticated(true);
      } else {
        throw new Error('A intervenit o eroare');
      }
    } catch (error: any) {
      if (axios.isAxiosError(error) && error.response) {
        // Handle different error statuses
        if (error.response.status === 422) {
          throw new Error('Eroare: ' + (error.response.data.message || ''));
        } else {
          throw new Error('Eroare: ' + (error.response.data.message || ''));
        }
      } else {
        throw new Error('A intervenit o eroare');
      }
    }
  };

  const logout = async () => {
    try {
      const token = localStorage.getItem('token');
      if (token) {
        await axios.post('https://hub.itvisionsoftware.ro/api/logout', {}, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          withCredentials: true,
        });

        localStorage.removeItem('token');
        setUserName(null);
        setIsAuthenticated(false);
      }
    } catch (error) {
      console.error('Logout error:', error);
    }
  };

  return (
    <AuthContext.Provider value={{ userName, isAuthenticated, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

const useAuth = () => {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

export { AuthProvider, useAuth };
