import React, { useState, useRef, useEffect } from 'react';
import { Container, Typography, TextField, Button, Grid, Box, Link, Snackbar, Alert, CircularProgress, Paper, Skeleton, Divider, FormGroup, FormControl, FormControlLabel, Switch } from '@mui/material';
import axios from 'axios';
import { styled } from '@mui/material/styles';
import ReCAPTCHA from 'react-google-recaptcha';
import SendIcon from '@mui/icons-material/Send';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import EmailIcon from '@mui/icons-material/Email';
import PersonIcon from '@mui/icons-material/Person';
import MessageIcon from '@mui/icons-material/Message';
import Cookies from 'js-cookie';
import ConnectWithoutContactIcon from '@mui/icons-material/ConnectWithoutContact';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import { Fade,Slide, Bounce } from "react-awesome-reveal";
import EchipaNoastra from '../Componente/EchipaNoastra';
import { motion } from 'framer-motion';
import { Link as RouterLink } from 'react-router-dom';


const StyledContainer = styled(Container)(({ theme }) => ({
  padding: '2rem',
  marginTop: '2rem',
  borderRadius: '16px',
  color: '#fff',
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: '2rem',
  borderRadius: '16px',
  boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
  backgroundColor: '#fff',
  overflow: 'hidden',
  '@media (max-width:600px)': {
    padding: '1.5rem',
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  marginTop: '1rem',
  backgroundColor: '#007aff',
  color: '#fff',
  padding: '12px 24px',
  borderRadius: '12px',
  boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.2)',
  transition: 'background-color 0.3s, transform 0.3s',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '&:hover': {
    backgroundColor: '#005bb5',
    transform: 'scale(1.03)',
  },
  position: 'relative',
  '&.loading': {
    backgroundColor: '#005bb5',
    cursor: 'not-allowed',
  },
  '&.submitted': {
    backgroundColor: '#4caf50',
    cursor: 'default',
  },
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  backgroundColor: '#fff',
  borderRadius: '12px',
  '& .MuiOutlinedInput-root': {
    borderRadius: '12px',
  },
  '& .MuiInputLabel-root': {
    color: '#333',
  },
  '& .MuiInputAdornment-root': {
    color: '#007aff',
  },
}));

const FormContainer = styled(Box)(({ theme }) => ({
  backgroundColor: '#fff',
  borderRadius: '16px',
  padding: '2rem',
  boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.2)',
  border: '1px solid #e0e0e0',
  position: 'relative',
  overflow: 'hidden',
}));

const Header = styled(Box)(({ theme }) => ({
  borderBottom: '1px solid #e0e0e0',
  paddingBottom: '2rem',
  marginBottom: '1rem',
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: '-10px',
    left: '15px',
    width: '12px',
    height: '12px',
    borderRadius: '50%',
    backgroundColor: '#ff605c',
  },
  '&::after': {
    content: '""',
    position: 'absolute',
    top: '-10px',
    left: '32px',
    width: '12px',
    height: '12px',
    borderRadius: '50%',
    backgroundColor: '#ffbd44',
  },
}));

const TestimonialsContainer = styled(Box)(({ theme }) => ({
  marginTop: '4rem',
  padding: '2rem',
  backgroundColor: '#fff',
  borderRadius: '16px',
  boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.2)',
}));

const Testimonial = styled(Paper)(({ theme }) => ({
  padding: '1.5rem',
  margin: '1rem 0',
  borderRadius: '12px',
  boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.1)',
}));

const ContactPage: React.FC = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error'>('success');
  const [captchaValue, setCaptchaValue] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [inViewport, setInViewport] = useState(false);
  const [checked, setChecked] = useState(false);

  const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  const formRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setTimeout(() => {
            setInViewport(true);
          }, 1000);
          observer.disconnect();
        }
      },
      { threshold: 0.1 }
    );

    if (formRef.current) {
      observer.observe(formRef.current);
    }

    return () => {
      if (formRef.current) {
        observer.unobserve(formRef.current);
      }
    };
  }, []);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const handleCaptchaChange = (value: string | null) => {
    setCaptchaValue(value);
  };

  const validateEmail = (email: string) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  };

  const checkNecessaryCookies = () => {
    const cookiePreferences = Cookies.get('cookiePreferences');
    if (cookiePreferences) {
      try {
        const parsedPreferences = JSON.parse(cookiePreferences);
        return parsedPreferences.necessary === true;
      } catch {
        return false;
      }
    }
    return false;
  };
  const routeVariants = {
    initial: {
      opacity: 0,
      y: 20,
      scale: 0.8,
    },
    final: {
      opacity: 1,
      y: 0,
      scale: 1,
      transition: {
        duration: 0.5,
        ease: 'easeInOut',
      },
    },
  };
  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    
    if (!checkNecessaryCookies()) {
      setSnackbarMessage('Trebuie să acceptați cookie-urile necesare pentru a trimite formularul.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      return;
    }

    if (!formData.email || !validateEmail(formData.email)) {
      setSnackbarMessage('Adresa de email nu este validă.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      return;
    }

    if (!captchaValue) {
      setSnackbarMessage('Vă rugăm să completați reCAPTCHA.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      return;
    }

    if (!checked) {
      setSnackbarMessage('Trebuie să acceptați termenii si condițiile pentru a transmite cererea de contact');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      return;
    }


    setLoading(true);
    try {
      const response = await axios.post('https://hub.itvisionsoftware.ro/api/contact', {
        ...formData,
        recaptcha: captchaValue,
      });
      
      if (response.status === 200) {
        setSnackbarMessage('Formular trimis cu succes!');
        setSnackbarSeverity('success');
        setFormData({
          name: '',
          email: '',
          message: '',
        });
        setCaptchaValue(null);
        setSubmitted(true);
      }
    } catch (error) {
      setSnackbarMessage('A apărut o eroare la trimiterea formularului. Vă rugăm să încercați din nou.');
      setSnackbarSeverity('error');
    } finally {
      setLoading(false);
      setSnackbarOpen(true);
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  useEffect(() => {
    // Get the height of the viewport
    const viewportHeight = window.innerHeight;
    
    // Scroll smoothly by 100vh
    window.scrollTo({
      top: 0.8 * viewportHeight,
      behavior: 'smooth'
    });
  }, []);
  

  return (
    <motion.div
    variants={routeVariants}
    initial="initial"
    animate="final"
    className="acasa-component"
  >
    
       <div id="contactid"></div>
   <div className='fundalContact'>
   <Divider>
        <Typography variant="h2" component="h1" gutterBottom sx={{ color:'black',fontWeight: 700, mt: 4, fontSize: { xs: '1.8rem', sm: '2.5rem', md: '3rem', lg: '4rem' } }}>
          CONTACT
        </Typography>
       </Divider>
      <StyledContainer maxWidth="xl" style={{position:'relative'}}>

     
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <StyledPaper ref={formRef}>
              
            {!inViewport ? (
                  <Skeleton variant="rectangular" width="50%" height={45} sx={{ margin: 'auto', mb: 4 }} />
                ) : (
                  <>   
                  <Typography variant="h4" align="center" style={{ color: '#333', textAlign: 'center' }}>
                    INFORMAȚII
                  </Typography>
                  
                  </>
                )}
 {!inViewport ? (
                <>
                  <Skeleton variant="circular" sx={{width:'60px', height:'60px', margin:'auto',  mb:2 }} />
            
                  </>
                ) : (
                  <>   
                          <ConnectWithoutContactIcon sx={{fontSize:60, color:'lightgray'}} />
                  
                  </>
                )}
              <Box mb={2}>

              {!inViewport ? (
                <>
                  <Skeleton variant="rectangular" width="80%" height={38} sx={{ margin: 'auto', mb: 4 }} />
                  <Skeleton variant="rectangular" width="90%" height={38} sx={{ margin: 'auto', mb: 4 }} />
                  </>
                ) : (
                  <>   
                  <Typography variant="body1" style={{ marginBottom: '0.5rem' }}>
                  <strong>Email:</strong> <a href="mailto:office@itvisionsoftware.ro" style={{ color: '#007aff', textDecoration: 'none' }}>office@itvisionsoftware.ro</a>
                </Typography>
                <Typography variant="body1">
                  <strong>Telefon:</strong> <a href="tel:+40770674079" style={{ color: '#007aff', textDecoration: 'none' }}>0770 677 409</a>
                </Typography>
                  
                  </>
                )}
               
              </Box>
              <Box mb={2}>

              {!inViewport ? (
                <>
                  <Skeleton variant="rectangular" width="95%" height={220} sx={{ margin: 'auto', mb: 4 }} />
                
                  </>
                ) : (
                  <>   
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2719.160224326653!2d27.572328915613504!3d47.17442497915788!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40cafb8aeab68fa9%3A0x807c0b0b0a09ef13!2sIa%C8%99i%2C%20Romania!5e0!3m2!1sen!2sus!4v1625241410202!5m2!1sen!2sus"
                  width="100%"
                  height="300"
                  style={{ border: 0 }}
                  loading="lazy"
                  title="Google Maps - Iași, Romania"
                ></iframe>
                  </>
                )}
               
              </Box>
            </StyledPaper>
          </Grid>
          <Grid item xs={12} md={6}>
            <StyledPaper >
              <Header>
                {!inViewport ? (
                  <Skeleton variant="rectangular" width="80%" height={56} sx={{ margin: 'auto', mb: 4 }} />
                ) : (
                  <>   
                  <Typography variant="h4" align="center" style={{ color: '#333', textAlign: 'center' }}>
                    Contactează-ne
                  </Typography>
                  
                  </>
                )}
              </Header>
              <ForwardToInboxIcon sx={{fontSize:60, color:'lightgray'}} />
              {!inViewport ? (
                <>
                  <Skeleton variant="rectangular" width="100%" height={60} sx={{ mb: 2 }} />
                  <Skeleton variant="rectangular" width="100%" height={60} sx={{ mb: 2 }} />
                  <Skeleton variant="rectangular" width="100%" height={60} sx={{ mb: 2 }} />
                  <Skeleton variant="rectangular" width="100%" height={118} sx={{ mb: 2 }} />
                  <Skeleton variant="rectangular" width="100%" height={40} sx={{ mb: 2 }} />
                </>
              ) : (
                <form noValidate autoComplete="off" onSubmit={handleSubmit}>
                  <StyledTextField
                    label="Nume"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    required
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    InputProps={{
                      startAdornment: <PersonIcon sx={{ color: '#007aff', mr: 1 }} />,
                    }}
                  />
                  <StyledTextField
                    label="Email"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    type="email"
                    required
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    InputProps={{
                      startAdornment: <EmailIcon sx={{ color: '#007aff', mr: 1 }} />,
                    }}
                  />
                  <StyledTextField
                    label="Mesaj"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    multiline
                    rows={4}
                    required
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                    InputProps={{
                      startAdornment: <MessageIcon sx={{ color: '#007aff', mr: 1 }} />,
                    }}
                  />
                
              
     
                <Box display="flex" alignItems="center" justifyContent="flex-start" mb={2}>
        <FormControlLabel
          control={
            <Switch
              checked={checked}
              onChange={handleSwitchChange}
              color="primary"
            />
          }
          label={
            <Typography variant="body2">
              Sunt de acord cu{' '}
              <RouterLink to="/TermsAndConditions" style={{}}>
                termenii și condițiile
              </RouterLink>
            </Typography>
          }
          sx={{ m: 0 }} // Remove default margin if needed
        />
      </Box>
                  <Box mt={2} mb={2}>
                    <ReCAPTCHA
                      sitekey="6LfWYRUqAAAAAAxwxC3ulKCLEEy1wkgmwN8E5rEE"
                      onChange={handleCaptchaChange}
                    />
                  </Box>
                  <StyledButton
                    type="submit"
                    variant="contained"
                    className={`${loading ? 'loading' : ''} ${submitted ? 'submitted' : ''}`}
                    disabled={loading || submitted}
                  >
                    {loading && <CircularProgress size={24} color="inherit" style={{ marginRight: '8px' }} />}
                    {submitted ? <><CheckCircleIcon style={{ marginRight: '8px' }} /> Trimis</> : <><SendIcon style={{ marginRight: '8px' }} /> Trimite</>}
                  </StyledButton>
                </form>
              )}
            </StyledPaper>
          </Grid>
        </Grid>
        
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          action={
            <Button color="inherit" onClick={handleCloseSnackbar}>
              Închide
            </Button>
          }
        >
          <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity}>
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </StyledContainer>

      </div>
    </motion.div>
  );
};

export default ContactPage;
