import React, { useState, useEffect, useRef } from 'react';
import { Container, Typography, Box, Button, Paper, Skeleton } from '@mui/material';
import { styled } from '@mui/material/styles';
import { AccessTime, CalendarToday, HourglassEmpty, Timer } from '@mui/icons-material';
import { NavLink } from 'react-router-dom';

// Styled component for the countdown timer box
const TimerBox = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  textAlign: 'center',
  backgroundColor: '#ffffff',
  boxShadow: theme.shadows[6],
  borderRadius: theme.shape.borderRadius,
  border: `1px solid ${theme.palette.divider}`,
  transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
  '&:hover': {
    transform: 'scale(1.05)',
    boxShadow: theme.shadows[10],
  },
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}));

// Styled component for the countdown timer numbers
const TimerText = styled(Typography)(({ theme }) => ({
  fontSize: '1.5rem',
  fontWeight: 700,
  color: theme.palette.text.primary,
  [theme.breakpoints.up('sm')]: {
    fontSize: '2rem',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '2.5rem',
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: '3rem',
  },
}));

// Styled component for the timer labels
const TimerLabel = styled(Typography)(({ theme }) => ({
  fontSize: '0.75rem',
  color: theme.palette.text.secondary,
  [theme.breakpoints.up('sm')]: {
    fontSize: '1rem',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '1.2rem',
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: '1.5rem',
  },
}));

// Styled component for the timer section
const TimerSection = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexWrap: 'wrap',
  gap: theme.spacing(2),
  [theme.breakpoints.up('sm')]: {
    gap: theme.spacing(4),
  },
}));

// Countdown timer calculation function
const calculateTimeLeft = (targetDate: Date) => {
  const now = new Date();
  const difference = targetDate.getTime() - now.getTime();
  let timeLeft = {
    days: Math.floor(difference / (1000 * 60 * 60 * 24)),
    hours: Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
    minutes: Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60)),
    seconds: Math.floor((difference % (1000 * 60)) / 1000),
  };
  return timeLeft;
};

const ComingSoon: React.FC = () => {
  const targetDate = new Date('2024-09-01T00:00:00'); // Set your target date here
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(targetDate));
  const [loading, setLoading] = useState(true);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // Start countdown timer
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft(targetDate));
    }, 1000);

    // Set up IntersectionObserver
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          // Introduce a delay before hiding the loading skeleton
          setTimeout(() => {
            setLoading(false);
          }, 1000); // Delay of 1000ms for more realistic loading effect
          observer.unobserve(entry.target); // Optional: Stop observing after first intersection
        }
      },
      { threshold: 0.1 } // Adjust threshold as needed
    );

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => {
      clearInterval(timer);
      if (containerRef.current) {
        observer.unobserve(containerRef.current);
      }
    };
  }, [targetDate]);
  useEffect(() => {
    // Selectează elementul după ID și aplică scroll
    const element = document.getElementById('sooninceput');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);
  return (
    <Container
      component="main"
      ref={containerRef}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        width: '100%',
        color: '#333',
        textAlign: 'center',
        p: 3,
        overflow: 'hidden',
        position: 'relative',
      }}

      id="sooninceput"
    >

      {loading ? (
        <Box sx={{position:'relative', width: '100%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>

          <Skeleton variant="text" width="80%" height={50} sx={{ mb: 2 }} />
          <Skeleton variant="text" width="60%" height={40} sx={{ mb: 2 }} />
          <Skeleton variant="rectangular" width="90%" height={150} sx={{ mb: 4, borderRadius: 2 }} />
          <Box display="flex" flexWrap="wrap" justifyContent="center" width="90%" sx={{ mb: 4 }}>
            {[...Array(4)].map((_, index) => (
              <Box key={index} sx={{ mx: 1, textAlign: 'center' }}>
                <Skeleton variant="circular" width={60} height={60} sx={{ mb: 1 }} />
                <Skeleton variant="text" width={60} height={30} />
              </Box>
            ))}
          </Box>
          <Skeleton variant="rectangular" width="40%" height={50} sx={{ borderRadius: 20 }} />
        </Box>
      ) : (
        <>
                                    <img src='imagini/bg-grid.svg' alt='IT VISION SOFTWARE' style={{position:'absolute', width:'100%', height:'100%', left:0, top:0, zIndex:-1}}></img>

          <Typography variant="h2" component="h1" gutterBottom sx={{ fontWeight: 700, fontSize: { xs: '1.8rem', sm: '2.5rem', md: '3rem', lg: '4rem' } }}>
            Suntem în plin proces de transformare!
          </Typography>
          <Typography variant="h5" component="h2" gutterBottom sx={{ fontSize: { xs: '1rem', sm: '1.2rem', md: '1.5rem', lg: '1.8rem' } }}>
            Ne-am mutat pentru a aduce o experiență și mai bună. Aceasta secțiune va fi disponibilă în curând!
          </Typography>
          <TimerBox>
            <TimerSection>
              <Box textAlign="center">
                <TimerText>{timeLeft.days}</TimerText>
                <TimerLabel>
                  <CalendarToday sx={{ fontSize: '1.5rem' }} />
                  <br /> Zile
                </TimerLabel>
              </Box>
              <Typography variant="h3" color="textSecondary" sx={{ fontSize: { xs: '1.5rem', sm: '2rem', md: '2.5rem', lg: '3rem' } }}>:</Typography>
              <Box textAlign="center">
                <TimerText>{timeLeft.hours}</TimerText>
                <TimerLabel>
                  <AccessTime sx={{ fontSize: '1.5rem' }} />
                  <br /> Ore
                </TimerLabel>
              </Box>
              <Typography variant="h3" color="textSecondary" sx={{ fontSize: { xs: '1.5rem', sm: '2rem', md: '2.5rem', lg: '3rem' } }}>:</Typography>
              <Box textAlign="center">
                <TimerText>{timeLeft.minutes}</TimerText>
                <TimerLabel>
                  <Timer sx={{ fontSize: '1.5rem' }} />
                  <br /> Minute
                </TimerLabel>
              </Box>
              <Typography variant="h3" color="textSecondary" sx={{ fontSize: { xs: '1.5rem', sm: '2rem', md: '2.5rem', lg: '3rem' } }}>:</Typography>
              <Box textAlign="center">
                <TimerText>{timeLeft.seconds}</TimerText>
                <TimerLabel>
                  <HourglassEmpty sx={{ fontSize: '1.5rem' }} />
                  <br /> Secunde
                </TimerLabel>
              </Box>
            </TimerSection>
          </TimerBox>
          <Box mt={4}>
            <Button
              variant="contained"
              color="primary"
             component={NavLink}
             to="/contact"
              sx={{
                backgroundColor: '#000000',
                color: '#ffffff',
                '&:hover': {
                  backgroundColor: '#333333',
                },
                borderRadius: 20,
                px: 4,
                py: 2,
                fontWeight: 600,
                textTransform: 'none',
              }}
            >
              Contactați-ne
            </Button>
          </Box>
        </>
      )}
    </Container>
  );
};

export default ComingSoon;
