import React, { useEffect, useRef } from 'react';
import { Box, Grid, useTheme, useMediaQuery } from '@mui/material';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

// Importează imaginile
import img1 from './imaginiportofoliu/autoguard.jpeg';
import img2 from './imaginiportofoliu/rentmeup2.jpeg';
import img3 from './imaginiportofoliu/home4u.jpeg';
import img4 from './imaginiportofoliu/SneakersZone.jpeg';
import { Rotate90DegreesCcw } from '@mui/icons-material';


// Funcție pentru amestecare
const shuffleArray = (array: string[]): string[] => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
};

// Funcție pentru generarea coloanelor de imagini fără repetiție laterală și verticală
const generateUniqueColumns = (images: string[]): string[][] => {
  const numColumns = 3;
  const numRows = Math.ceil(images.length / numColumns);
  
  if (images.length < numColumns) {
    throw new Error('Numărul imaginilor trebuie să fie cel puțin egal cu numărul coloanelor');
  }

  const shuffledImages = shuffleArray([...images]);
  const matrix: string[][] = Array.from({ length: numRows }, () => Array(numColumns).fill(''));

  let imageIndex = 0;
  
  for (let row = 0; row < numRows; row++) {
    for (let col = 0; col < numColumns; col++) {
      matrix[row][col] = shuffledImages[imageIndex];
      imageIndex = (imageIndex + 1) % shuffledImages.length;
    }
  }

  const columns: string[][] = Array.from({ length: numColumns }, () => []);
  for (let col = 0; col < numColumns; col++) {
    for (let row = 0; row < numRows; row++) {
      columns[col][row] = matrix[row][col];
    }
  }

  return columns;
};

// Componente React pentru slider de imagini
const ImageGrid: React.FC = () => {
  const columnRefs = [useRef<HTMLDivElement | null>(null), useRef<HTMLDivElement | null>(null), useRef<HTMLDivElement | null>(null)];
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const images = [img1, img2, img3, img4, img1, img2, img3, img4, img1, img2, img3, img4, img1, img2, img3, img4, img1, img2, img3, img4];

  const columns = generateUniqueColumns(images);

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    if (columnRefs[0].current) {
      gsap.fromTo(
        columnRefs[0].current,
        { yPercent: 0 },
        {
          yPercent: -150,
          ease: 'none',
          scrollTrigger: {
            trigger: columnRefs[0].current,
            start: 'top bottom',
            end: 'bottom top',
            scrub: true,
          },
        }
      );
    }

    if (columnRefs[1].current) {
      gsap.fromTo(
        columnRefs[1].current,
        { yPercent: -150 },
        {
          yPercent: 0,
          ease: 'none',
          scrollTrigger: {
            trigger: columnRefs[1].current,
            start: 'top bottom',
            end: 'bottom top',
            scrub: true,
          },
        }
      );
    }

    if (columnRefs[2].current) {
      gsap.fromTo(
        columnRefs[2].current,
        { yPercent: 0 },
        {
          yPercent: -150,
          ease: 'none',
          scrollTrigger: {
            trigger: columnRefs[2].current,
            start: 'top bottom',
            end: 'bottom top',
            scrub: true,
          },
        }
      );
    }

    return () => {
      ScrollTrigger.getAll().forEach(trigger => trigger.kill());
    };
  }, []);

  return (
    <Box
      sx={{
        width: '100%',
        padding: isMobile ? '0 5px' : '0 20px',
        background: 'linear-gradient(237deg, rgba(12,32,88,1) 0%, rgba(1,9,28,1) 100%)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: isMobile ? '20vh' : '50vh',
        overflow: 'hidden',
        mt: 10,
      }}
    >
      <Grid container spacing={isMobile ? 1 : 3} sx={{ maxWidth: isMobile ? '100%' : '90%', height: '100%' }}>
        {columns.map((columnImages, columnIndex) => (
          <Grid
            item
            xs={4}
            key={`column-${columnIndex}`}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              textAlign: 'center',
              borderRadius: '8px',
              padding: '0',
          
         
              overflowX:'visible',
              
              position: 'relative',
              height: '100%',
            }}
          >
            <Box
              ref={columnRefs[columnIndex]}
              sx={{
                
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                position: 'relative',
                width: '100%',
                height: '100%',
              }}
            >
              {columnImages.map((image, imgIndex) => (
                <Box
                  key={`img-${columnIndex}-${imgIndex}`}
                  component="img"
                  src={image}
                  alt={`img-${imgIndex}`}
                  sx={{
                
                    width: '100%',
                    height: 'auto',
                    maxHeight: 'calc(100vh / 3)',
                    objectFit: 'cover',
                    marginBottom: isMobile ? 1 : 1.5,
                    marginTop: isMobile ? 1 : 1.5,
                    borderRadius:'5px',
                    
                    transition: 'box-shadow 0.3s ease, transform 0.3s ease',
                    '&:hover': {
                      boxShadow: '0 0 15px 5px rgba(255, 255, 255, 0.8)',
                      transform: 'scale(1.05)',
                    },
                  }}
                />
              ))}
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default ImageGrid;
