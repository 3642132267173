import React from 'react';
import { Helmet } from 'react-helmet';

const SEOHelmet = () => (
  <Helmet>
    <title>IT VISION SOFTWARE - Dezvoltare Web, SEO, și Software în România</title>
    <meta
      name="description"
      content="IT VISION SOFTWARE oferă servicii complete de dezvoltare web, inclusiv dezvoltare site-uri web personalizate, dezvoltare WordPress, optimizare SEO, și soluții software avansate. Contactați-ne pentru servicii de dezvoltare web în România, soluții IT personalizate și consultanță de înaltă calitate."
    />
    <meta
      name="keywords"
      content="dezvoltare web, dezvoltare site-uri web, dezvoltare WordPress, servicii SEO, optimizare SEO, soluții software, dezvoltare software, dezvoltare aplicații web, consultanță IT, soluții IT în România, agenție dezvoltare web, design web personalizat, dezvoltare front-end, dezvoltare back-end"
    />
        <meta
      property="og:type"
      content="website"
    />
    <meta
      property="og:title"
      content="IT VISION SOFTWARE - Dezvoltare Web și SEO în România"
    />
    <meta
      property="og:description"
      content="IT VISION SOFTWARE oferă servicii de dezvoltare web, inclusiv dezvoltare site-uri web personalizate, optimizare SEO, și soluții software avansate. Contactați-ne pentru cele mai bune soluții IT în România."
    />
    <meta
      property="og:image"
      content="https://www.facebook.com/people/It-Vision-Software/61556909073114/"
    />
    <meta
      property="og:url"
      content="https://itvisionsoftware.ro"
    />
     <meta
      name="robots"
      content="index, follow"
    />
 
  </Helmet>
);

export default SEOHelmet;
