import React from 'react';
import { Parallax } from 'react-parallax';
import {useTheme, useMediaQuery } from '@mui/material';
import LazyLoad from 'react-lazyload';

const ParallaxComponent = () => {

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <div style={{ position: 'absolute', top: isMobile ? 0 :'14%', left: 0, width: '100%', height: '98%', zIndex: -1 }}>

      <Parallax bgImage="/imagini/meetingwallpaper.jpg" strength={350} style={{}}>
        <div style={{ height: '100vh', width: '100%', position: 'relative' }}>

          <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', background: 'radial-gradient(circle, rgba(10,27,43,0.75) 0%, rgba(1,3,4,1) 100%)'}}></div>

        </div>
      </Parallax>
  
    </div>
  );
};

export default ParallaxComponent;
