import React, { useEffect, useRef, useState } from 'react';
import { Fireworks } from 'fireworks-js';

const FireworksComponent: React.FC = () => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [isFadingOut, setIsFadingOut] = useState(false);

  useEffect(() => {
    if (containerRef.current) {
      const options = {
        speed: 3,
        acceleration: 1.05,
        friction: 0.97,
        gravity: 1.5,
        particles: 70,
        trace: 3,
        explosion: 5,
        intensity: 20,
        flickering: 30,
        lineWidth: {
          trace: { min: 1, max: 2 },
          explosion: { min: 1, max: 3 },
        },
        hue: {
          min: 0,
          max: 360,
        },
        delay: {
          min: 15,
          max: 30,
        },
        brightness: {
          min: 50,
          max: 80,
          decay: {
            min: 0.015,
            max: 0.03,
          },
        },
      };

      const fireworks = new Fireworks(containerRef.current, options);
      fireworks.start();

      return () => {
        setIsFadingOut(true);
        setTimeout(() => {
          fireworks.stop();
          setIsFadingOut(false);
        }, 3000); // Adjust the delay time as needed
      };
    }
  }, []);

  return (
    <div 
      ref={containerRef} 
      style={{ 
        position: 'absolute', 
        bottom: '-100px', 
        left: 0, 
        pointerEvents: 'none', 
        height: '80%', 
        width: '90%', 
        transform: 'translateY(-80%)',
        opacity: isFadingOut ? 0 : 1,
        transition: 'opacity 3s ease-in-out', // Adjust the duration to match the delay time
      }} 
    />
  );
};

export default FireworksComponent;
