import React, { useState, useEffect } from 'react';
import { Container, Typography, Box, useTheme, useMediaQuery, Paper, Tabs, Tab } from '@mui/material';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { motion } from 'framer-motion';

gsap.registerPlugin(ScrollTrigger);

const TermsAndConditions: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [selectedLanguage, setSelectedLanguage] = useState('ro');

  const handleLanguageChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    setSelectedLanguage(newValue);
  };

  const routeVariants = {
    initial: {
      opacity: 0,
      y: 20,
    },
    final: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.8,
        ease: 'easeInOut',
      },
    },
  };

  useEffect(() => {
    gsap.fromTo(
      '.terms-title',
      { opacity: 0, y: -50 },
      {
        opacity: 1,
        y: 0,
        duration: 1,
        ease: 'power4.out',
        scrollTrigger: {
          trigger: '.terms-title',
          start: 'top 80%',
        },
      }
    );

    gsap.fromTo(
      '.terms-content',
      { opacity: 0 },
      {
        opacity: 1,
        duration: 1.5,
        ease: 'power4.out',
        scrollTrigger: {
          trigger: '.terms-content',
          start: 'top 90%',
        },
      }
    );
  }, []);
  useEffect(() => {
    // Get the height of the viewport
    const viewportHeight = window.innerHeight;
    
    // Scroll smoothly by 100vh
    window.scrollTo({
      top: 0.8 * viewportHeight,
      behavior: 'smooth'
    });
  }, []);
  return (
    <motion.div
      variants={routeVariants}
      initial="initial"
      animate="final"
      className="terms-component"
    >
      <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
        <Paper elevation={3} sx={{ p: 4 }}>
          <Box className="terms-title" mb={4}>
         
          </Box>
          <Box className="terms-tabs" mb={4}>
            <Tabs
              value={selectedLanguage}
              onChange={handleLanguageChange}
              centered
              indicatorColor="primary"
              textColor="primary"
            >
              <Tab value="ro" label="Română" />
              <Tab value="en" label="English" />
            </Tabs>
          </Box>
          <Box className="terms-content" sx={{ textAlign:'left' }}>
            {selectedLanguage === 'ro' && (
              <Typography variant="body1" paragraph>
       <p><br /></p>
         
        <Typography variant="h2" component="h1" gutterBottom sx={{ fontWeight: 700, fontSize: { xs: '1.8rem', sm: '1.9rem', md: '2rem', lg: '3.0rem' }}}>
        
        Termeni Și Condiții
                   
                    </Typography>
 
            <p><br /></p>
            <p>Bun venit pe platforma online a IT VISION SOFTWARE S.R.L., accesată la adresa <a data-fr-linked="true" href="//www.itvisionsoftware.ro">www.itvisionsoftware.ro</a>, un spațiu virtual dedicat dezvoltării și furnizării de servicii software. Prin accesarea și utilizarea acestui site, precum și a produselor/serviciilor oferite de IT VISION SOFTWARE S.R.L., sunteți de acord să consultați și să acceptați detaliile prezentate &icirc;n Termenii și Condițiile de utilizare, alături de Politica de Confidențialitate a Datelor și Politica privind Cookie-urile, care vor fi expuse &icirc;n mod detaliat &icirc;n continuare.</p>
            <p><br /></p>
            <p><strong>OPERATORUL PLATFORMEI ONLINE</strong></p>
            <p><br /></p>
            <p>
                <a data-fr-linked="true" href="//www.itvisionsoftware.ro">www.itvisionsoftware.ro</a> reprezintă un serviciu online administrat de IT VISION SOFTWARE S.R.L., Compania este &icirc;nregistrată la Registrul Comerțului sub numărul
                J22/679/2024, av&acirc;nd Codul Unic de &Icirc;nregistrare 49642704.
            </p>
            <p><br /></p>
            <p>Subliniem că ne rezervăm dreptul de a efectua orice modificări at&acirc;t &icirc;n ceea ce privește Termenii și Condițiile, c&acirc;t și &icirc;n ceea ce privește conținutul site-ului <a data-fr-linked="true" href="//www.itvisionsoftware.ro">www.itvisionsoftware.ro</a>, fără a furniza o notificare prealabilă. &Icirc;n orice moment, puteți accesa pagina dedicată Termenilor și Condițiilor pentru a consulta cea mai recentă versiune a acestor prevederi.</p>
            <p><br /></p>
            <p><strong>PREZENTAREA SERVICIILOR ȘI DESCRIEREA PRODUSELOR</strong></p>
            <p><br /></p>
            <p>IT VISION SOFTWARE S.R.L. pune la dispoziție servicii software și &icirc;și rezervă dreptul de a aduce modificări oric&acirc;nd datelor tehnice prezentate pe <a data-fr-linked="true" href="//www.itvisionsoftware.ro">www.itvisionsoftware.ro</a>, fără notificare prealabilă. Descrierile produselor și serviciilor prezente pe acest site au un caracter informativ și nu constituie obligații contractuale. Fotografiile afișate au rolul de a oferi o prezentare generală și pot să difere de aspectul final al produselor sau serviciilor oferite.</p>
            <p><br /></p>
            <p>IT VISION SOFTWARE S.R.L. nu &icirc;și asumă responsabilitatea pentru eventualele daune rezultate din utilizarea produselor achiziționate &icirc;n afara scopurilor specificate de către producător.</p>
            <p><br /></p>
            <p><a data-fr-linked="true" href="//www.itvisionsoftware.ro">www.itvisionsoftware.ro</a> utilizează descrieri sugestive pentru produsele sale și asigură exactitatea informațiilor disponibile pe acest site. Fotografiile au un caracter informativ și pot prezenta diferențe.</p>
            <p><br /></p>
            <p><strong>GARANȚII ȘI LIMITĂRI</strong></p>
            <p><br /></p>
            <p><a data-fr-linked="true" href="//www.itvisionsoftware.ro">www.itvisionsoftware.ro</a> nu oferă garanții, fie expres sau implicite, cu privire la funcționarea sau accesibilitatea acestui site. Orice informații sau servicii furnizate sunt oferite &quot;așa cum sunt,&quot; &icirc;n funcție de disponibilitate și fără a aduce alte garanții. IT VISION SOFTWARE S.R.L. nu &icirc;și asumă responsabilitatea &icirc;n cazul &icirc;n care <a data-fr-linked="true" href="//www.itvisionsoftware.ro">www.itvisionsoftware.ro</a> nu este accesibil pentru utilizatori pe o perioadă nedeterminată, din motive tehnice sau comerciale.</p>
            <p><br /></p>
            <p>Pentru eventualele erori &icirc;n utilizare cauzate de modificări, setări, upgrade-uri etc., efectuate de furnizorul de hosting, responsabilitatea este exclusiv a acestuia. IT VISION SOFTWARE S.R.L. &icirc;și rezervă dreptul de a schimba parțial sau integral conținutul informațiilor prezentate pe site, fără notificare prealabilă a utilizatorilor. De asemenea, IT VISION SOFTWARE S.R.L. &icirc;și rezervă dreptul de a suspenda sau desființa acest site fără a notifica prealabil utilizatorii. Nu există nicio mențiune pe acest site cu privire la compatibilitatea dintre calculatorul utilizatorilor și platforma online. Accesarea acestui site se realizează de bună voie, iar utilizatorii &icirc;și asumă &icirc;n mod irevocabil și necondiționat responsabilitatea pentru această acțiune.</p>
            <p><br /></p>
            <p><strong>RECENZII, COMENTARII, COMUNICĂRI ȘI ALTE TIPURI DE CONȚINUT</strong></p>
            <p><br /></p>
            <p>Utilizatorii au posibilitatea să posteze recenzii, comentarii sau alte tipuri de conținut, precum sugestii, idei sau &icirc;ntrebări, cu condiția ca acestea să respecte următoarele cerințe:</p>
            <p><br /></p>
            <ul>
                <li> <p>să nu fie ilegale, obscene, amenințătoare sau defăimătoare;</p></li>
                <li> <p>să nu &icirc;ncalce dreptul la intimitate sau legea privind protecția proprietății intelectuale;</p></li>
                <li> <p>să nu conțină viruși informatici, comunicări comerciale, politice, instigare la ură sau intoleranță pe criterii etnice, religioase, rasiale sau de orientare sexuală, mesaje colective sau alte forme de &quot;spam&quot;.</p></li>
            </ul>
            <p>Pentru a posta astfel de conținut, nu este permisă utilizarea de adrese de e-mail sau identități false. <a data-fr-linked="true" href="//www.itvisionsoftware.ro">www.itvisionsoftware.ro</a> &icirc;și rezervă dreptul, fără a avea obligația, de a elimina sau edita orice conținut postat de către utilizatori și nu este obligată să revizuiască periodic conținutul postat. Prin postarea de recenzii, comentarii sau alte tipuri de conținut, transmiteți, cu excepții indicate de IT VISION SOFTWARE S.R.L., drepturi de proprietate intelectuală, &icirc;n mod neexclusiv, perpetuu, irevocabil, asupra materialelor postate. IT VISION SOFTWARE S.R.L. are dreptul de a modifica, reproduce, transmite, adapta, publica sau distribui aceste materiale prin orice mijloc de comunicare &icirc;n masă, din orice țară.</p>
            <p><br /></p>
            <p>Prin postarea de conținut, cedați dreptul IT VISION SOFTWARE S.R.L. de a utiliza numele dvs. &icirc;n legătură cu materialele postate și garantați că dețineți sau controlați drepturile de proprietate intelectuală asupra acestora. De asemenea, garantați că aceste materiale nu &icirc;ncalcă condițiile de postare ale <a data-fr-linked="true" href="//www.itvisionsoftware.ro">www.itvisionsoftware.ro</a>.</p>
            <p><br /></p>
            <p><a data-fr-linked="true" href="//www.itvisionsoftware.ro">www.itvisionsoftware.ro</a> are dreptul, dar nu obligația, de a monitoriza, edita sau șterge conținutul sau activitățile utilizatorilor pe site. <a data-fr-linked="true" href="//www.itvisionsoftware.ro">www.itvisionsoftware.ro</a> nu &icirc;și asumă responsabilitatea pentru materialele postate de terțe părți pe site și &icirc;și rezervă dreptul de a nu publica toate comentariile și articolele propuse de utilizatori.</p>
            <p><strong><br /></strong></p>
            <p><strong>FORȚA MAJORĂ</strong></p>
            <p><br /></p>
            <p><a data-fr-linked="true" href="//www.itvisionsoftware.ro">www.itvisionsoftware.ro</a>, afiliații săi și/sau furnizorii de produse sau servicii către platforma online nu pot fi făcuți responsabili pentru &icirc;nt&acirc;rzieri sau erori directe sau indirecte cauzate de factori independenți de voința <a data-fr-linked="true" href="//www.itvisionsoftware.ro">www.itvisionsoftware.ro</a>. Această exonerație include, dar nu se limitează la: erori &icirc;n funcționarea echipamentului tehnic, lipsa conexiunii la internet, probleme cu conexiunile telefonice, viruși informatici, acces neautorizat &icirc;n sistemele <a data-fr-linked="true" href="//www.itvisionsoftware.ro">www.itvisionsoftware.ro</a>, erori de operare, precum și evenimentele de forță majoră prevăzute de legislația &icirc;n vigoare.</p>
            <p><br /></p>
            <p><strong>PUBLICITATEA</strong></p>
            <p><br /></p>
            <p><a data-fr-linked="true" href="//www.itvisionsoftware.ro">www.itvisionsoftware.ro</a> poate afișa reclame la propriile produse, precum și la produse sau servicii ale terților. Prin utilizarea <a data-fr-linked="true" href="//www.itvisionsoftware.ro">www.itvisionsoftware.ro</a> și acceptarea Termenilor și Condițiilor de utilizare, precum și a Politicii de confidențialitate, vă exprimați acordul de a primi astfel de comunicări comerciale.</p>
            <p><br /></p>
            <p><strong>LITIGII</strong></p>
            <p><br /></p>
            <p>Prin vizualizarea produselor pe acest site, utilizatorul acceptă că legile rom&acirc;ne vor guverna Termenii și Condițiile, precum și orice dispute ce pot apărea &icirc;ntre utilizator și IT VISION SOFTWARE S.R.L.. &Icirc;n cazul unor conflicte, se va &icirc;ncerca rezolvarea amiabilă &icirc;n termen de minim 30 de zile lucrătoare.</p>
            <p><br /></p>
            <p>Dacă soluționarea amiabilă nu este posibilă, conflictul va fi supus deciziei instanței competente, conform legislației rom&acirc;ne &icirc;n vigoare.</p>
            <p><br /></p>
            <p><strong>TERMENI ȘI CONDIȚII GENERALE</strong></p>
            <p><br /></p>
            <p>Societatea furnizoare se angajează să furnizeze informații corecte, complete și actualizate despre produsele prezentate pe site.</p>
            <p><br /></p>
            <p>Societatea furnizoare nu garantează că <a data-fr-linked="true" href="//www.itvisionsoftware.ro">www.itvisionsoftware.ro</a>, serverele sale sau e-mailurile trimise nu conțin erori, omisiuni, defecte, &icirc;nt&acirc;rzieri sau &icirc;ntreruperi &icirc;n funcționare sau transmitere, căderi de linie sau alte factori similari inherenți mediului virtual.</p>
            <p><br /></p>
            <p>Societatea furnizoare &icirc;și declină responsabilitatea pentru omisiuni sau inadvertențe &icirc;n redactarea sau prezentarea informațiilor de pe site, inclusiv cele datorate unor operațiuni care, din motive tehnice, nu se pot actualiza &icirc;n timp real.</p>
            <p><br /></p>
            <p>Utilizatorul folosește <a data-fr-linked="true" href="//www.itvisionsoftware.ro">www.itvisionsoftware.ro</a> pe propriul său risc, iar societatea furnizoare nu poartă responsabilitate pentru daunele directe sau indirecte cauzate de utilizarea sau accesarea/vizitarea site-ului sau de folosirea informațiilor prezentate.</p>
            <p><br /></p>
            <p>Comerciantul, &icirc;n calitatea sa de destinatar, acționează pe riscul său &icirc;n situația &icirc;n care a fost sau ar fi trebuit să fie conștient, &icirc;n conformitate cu practicile comerciale curente sau cu proceduri convenite expres cu emitentul, că informația dintr-un mesaj electronic a fost alterată &icirc;n timpul transmiterii sau procesării.</p>
            <p><br /></p>
            <p>Niciuna dintre părțile contractuale nu poate fi făcută răspunzătoare pentru neexecutarea (totală/partială) sau executarea cu &icirc;nt&acirc;rziere a obligațiilor sale din cauza forței majore. Părțile &icirc;și vor notifica imediat situația și vor lua toate măsurile necesare pentru a limita consecințele evenimentului.</p>
            <p><br /></p>
            <p>Societatea furnizoare nu este răspunzătoare pentru pierderi cauzate de nerespectarea Termenilor și Condițiilor sau pentru prejudicii rezultate din nefuncționarea site-ului. Societatea furnizoare nu răspunde de daunele de orice natură suferite de client sau de terțe părți ca urmare a &icirc;ndeplinirii obligațiilor sale sau din utilizarea produselor și serviciilor prezentate pe site.</p>
            <p><br /></p>
            <p><strong>DIVERSE</strong></p>
            <p><br /></p>
            <p>&Icirc;n cazul &icirc;n care oricare dintre clauzele de mai sus este declarată nulă sau nevalidă, aceasta nu afectează valabilitatea celorlalte clauze. Prin acceptarea Termenilor și Condițiilor, clientul &icirc;și asumă &icirc;n totalitate drepturile și obligațiile derivate din vizitarea <a data-fr-linked="true" href="//www.itvisionsoftware.ro">www.itvisionsoftware.ro</a>.</p>
            <p><br /></p>
            <p><strong>CONTINUTUL SITE-ULUI</strong></p>
            <p><br /></p>
            <p><a data-fr-linked="true" href="//www.itvisionsoftware.ro">www.itvisionsoftware.ro</a> oferă informații despre serviciile IT VISION SOFTWARE S.R.L..</p>
            <p><br /></p>
            <p>Tot conținutul este strict informativ. <a data-fr-linked="true" href="//www.itvisionsoftware.ro">www.itvisionsoftware.ro</a> nu poate garanta corectitudinea informațiilor și nu &icirc;și asumă responsabilitatea pentru acuratețea, integritatea sau autenticitatea acestora. Acest site și informația oferită sunt furnizate fără garanție, fie exprimată, fie implicită.</p>
            <p><br /></p>
            <p><a data-fr-linked="true" href="//www.itvisionsoftware.ro">www.itvisionsoftware.ro</a> &icirc;și rezervă dreptul de a face schimbări &icirc;n orice moment, fără notificare prealabilă, &icirc;n ceea ce privește specificațiile și accesibilitatea produselor și serviciilor.</p>
            <p><br /></p>
            <p>Orice referință la produse sau servicii comerciale, procese sau alte informații prin menționarea numelui mărcii, a producătorului, a furnizorului sau a altor informații nu constituie sau implică susținerea sau recomandarea acestora de către <a data-fr-linked="true" href="//www.itvisionsoftware.ro">www.itvisionsoftware.ro</a>.</p>
            <p><br /></p>
            <p><strong>LIMITAREA RĂSPUNDERII</strong></p>
            <p><br /></p>
            <p><a data-fr-linked="true" href="//www.itvisionsoftware.ro">www.itvisionsoftware.ro</a> nu va fi responsabilă pentru orice daune directe, indirecte, accidentale, speciale sau de orice alt fel, rezultate din utilizarea sau incapacitatea de a utiliza informația sau conținutul de pe acest site. &Icirc;n cazul &icirc;n care utilizarea acestui site generează nevoia de service, repararea sau corectarea echipamentelor sau datelor dvs., veți suporta toate costurile asociate. Utilizarea acestui site este pe propria răspundere.</p>
            <p><br /></p>
            <p><strong>DREPTURILE DE AUTOR</strong></p>
            <p><br /></p>
            <p>Conținutul acestui site, inclusiv designul, textul, grafica, logo-urile, butoanele de iconițe, imagini, clipuri audio, descărcările digitale și selecția și aranjamentul acestora sunt proprietatea IT VISION SOFTWARE S.R.L. sau a furnizorilor săi și sunt protejate de legea drepturilor de autor internaționale.</p>
            <p><br /></p>
            <p>UTILIZAREA COMENTARIILOR, FEEDBACK-ULUI ȘI ALTOR SUBIECTE</p>
            <p><br /></p>
            <p>Dacă alegeți să ne trimiteți comentarii, feedback, idei, sau orice alt conținut, prin e-mail sau prin intermediul site-ului, vă rugăm să aveți &icirc;n vedere că aceste informații vor fi considerate non-confidențiale. Prin trimiterea acestor informații, ne acordați dreptul de a le utiliza, reproduce, modifica, adapta, publica, traduce și distribui &icirc;n &icirc;ntreaga lume &icirc;n orice mediu. Sunteți de acord că IT VISION SOFTWARE S.R.L. are dreptul de a utiliza numele pe care &icirc;l furnizați &icirc;n asociere cu conținutul respectiv, dacă dorește, dar nu este obligat să facă acest lucru. Nu suntem responsabili pentru niciun conținut postat de dvs. sau de către alte părți.</p>
            <p><br /></p>
            <p><strong>DISPOZIȚII FINALE</strong></p>
            <p><br /></p>
            <p>Termenii și Condițiile de mai sus constituie &icirc;ntregul acord dintre <a data-fr-linked="true" href="//www.itvisionsoftware.ro">www.itvisionsoftware.ro</a> și utilizator, &icirc;n ceea ce privește utilizarea acestui site, și &icirc;nlocuiesc toate &icirc;nțelegerile anterioare sau concomitente scrise sau orale.</p>
            <p><br /></p>
            <p>Niciun aspect al acestor Termeni și Condiții nu conferă, direct sau implicit, utilizatorului, alte părți sau altor terțe persoane, dreptul de a crea orice obligație sau răspundere pentru IT VISION SOFTWARE S.R.L..</p>
            <p><br /></p>
            <p><a data-fr-linked="true" href="//www.itvisionsoftware.ro">www.itvisionsoftware.ro</a> și IT VISION SOFTWARE S.R.L. &icirc;și rezervă dreptul de a modifica acești Termeni și Condiții &icirc;n orice moment, fără notificare prealabilă.</p>
            <p><br /></p>
            <p>Utilizatorul este de acord că, prin continuarea utilizării site-ului <a data-fr-linked="true" href="//www.itvisionsoftware.ro">www.itvisionsoftware.ro</a>, acceptă eventualele modificări ale Termenilor și Condițiilor.</p>
            <p><br /></p>
            <p>Prin utilizarea <a data-fr-linked="true" href="//www.itvisionsoftware.ro">www.itvisionsoftware.ro</a>, sunteți de acord să respectați Termenii și Condițiile menționate mai sus. Dacă nu sunteți de acord cu acești Termeni și Condiții, vă rugăm să nu utilizați acest site.</p>
         
            <p>Pentru orice alte informații suplimentare sau neclarități vă rugăm să ne contactați la adresa de e-mail support@itvisionsoftware.ro.</p>
            <p><br /></p>
            <p><strong>Ultima Actualizare: February 29, 2024</strong></p>
              </Typography>
            )}
            {selectedLanguage === 'en' && (
              <Typography variant="body1" paragraph>
              <p><br /></p>
              <Typography variant="h2" component="h1" gutterBottom sx={{ fontWeight: 700, fontSize: { xs: '1.8rem', sm: '1.9rem', md: '2rem', lg: '3.0rem' } }}>
        
        Terms And Conditions
                   
                    </Typography>
            <p><br /></p>
            <p>Welcome to the online platform of IT VISION SOFTWARE S.R.L., accessed at <a data-fr-linked="true" href="//www.itvisionsoftware.ro">www.itvisionsoftware.ro</a>, a virtual space dedicated to the development and provision of software services. By accessing and using this site, as well as the products/services offered by IT VISION SOFTWARE S.R.L., you agree to consult and accept the details presented in the Terms and Conditions of use, along with the Data Privacy Policy and Cookie Policy, which will be detailed below.</p>
            <p><br /></p>
            <p><strong>OPERATOR OF THE ONLINE PLATFORM</strong></p>
            <p><br /></p>
            <p><a data-fr-linked="true" href="//www.itvisionsoftware.ro">www.itvisionsoftware.ro</a> represents an online service managed by IT VISION SOFTWARE S.R.L. The company is registered with the Trade Registry Of Romania under the number J22/679/2024, having the Unique Registration Code 49642704.</p>
            <p><br /></p>
            <p>We emphasize that we reserve the right to make any changes both regarding the Terms and Conditions and the content of the <a data-fr-linked="true" href="//www.itvisionsoftware.ro">www.itvisionsoftware.ro</a> site without prior notice. At any time, you can access the dedicated Terms and Conditions page to consult the most recent version of these provisions.</p>
            <p><br /></p>
            <p><strong>PRESENTATION OF SERVICES AND PRODUCT DESCRIPTIONS</strong></p>
            <p><br /></p>
            <p>IT VISION SOFTWARE S.R.L. provides software services and reserves the right to make changes to technical data presented on <a data-fr-linked="true" href="//www.itvisionsoftware.ro">www.itvisionsoftware.ro</a> at any time without prior notice. The product and service descriptions on this site are for informational purposes only and do not constitute contractual obligations. The displayed photos serve to provide a general presentation and may differ from the final appearance of the products or services offered.</p>
            <p><br /></p>
            <p>IT VISION SOFTWARE S.R.L. does not assume responsibility for any damages resulting from the use of products purchased outside the purposes specified by the manufacturer.</p>
            <p><br /></p>
            <p><a data-fr-linked="true" href="//www.itvisionsoftware.ro">www.itvisionsoftware.ro</a> uses suggestive descriptions for its products and ensures the accuracy of the information available on this site. The photos are for informational purposes and may vary.</p>
            <p><br /></p>
            <p><strong>WARRANTIES AND LIMITATIONS</strong></p>
            <p><br /></p>
            <p><a data-fr-linked="true" href="//www.itvisionsoftware.ro">www.itvisionsoftware.ro</a> does not provide warranties, either express or implied, regarding the operation or accessibility of this site. Any information or services provided are offered "as is," based on availability and without any other warranties. IT VISION SOFTWARE S.R.L. is not responsible if <a data-fr-linked="true" href="//www.itvisionsoftware.ro">www.itvisionsoftware.ro</a> is not accessible to users for an indefinite period, due to technical or commercial reasons.</p>
            <p><br /></p>
            <p>For any errors in use caused by changes, settings, upgrades, etc., made by the hosting provider, the responsibility lies solely with them. IT VISION SOFTWARE S.R.L. reserves the right to partially or completely change the content of the information presented on the site without prior notice to users. Additionally, IT VISION SOFTWARE S.R.L. reserves the right to suspend or discontinue this site without prior notification to users. There is no mention on this site regarding the compatibility between users' computers and the online platform. Access to this site is voluntary, and users irrevocably and unconditionally assume responsibility for this action.</p>
            <p><br /></p>
            <p><strong>REVIEWS, COMMENTS, COMMUNICATIONS, AND OTHER TYPES OF CONTENT</strong></p>
            <p><br /></p>
            <p>Users have the possibility to post reviews, comments, or other types of content, such as suggestions, ideas, or questions, provided they meet the following requirements:</p>
            <p><br /></p>
            <p>
                - They must not be illegal, obscene, threatening, or defamatory; <br />
                - They must not violate the right to privacy or intellectual property protection law; <br />
                - They must not contain computer viruses, commercial communications, political messages, incitement to hatred or intolerance based on ethnic, religious, racial, or sexual orientation criteria, collective messages, or other forms of "spam." <br />
            </p>
            <p>To post such content, the use of false email addresses or identities is not allowed. <a data-fr-linked="true" href="//www.itvisionsoftware.ro">www.itvisionsoftware.ro</a> reserves the right, without obligation, to remove or edit any content posted by users and is not required to periodically review posted content. By posting reviews, comments, or other types of content, you transmit, with exceptions indicated by IT VISION SOFTWARE S.R.L., intellectual property rights, in a non-exclusive, perpetual, irrevocable manner, over the posted materials. IT VISION SOFTWARE S.R.L. has the right to modify, reproduce, transmit, adapt, publish, or distribute these materials by any means of mass communication, from any country.</p>
            <p><br /></p>
            <p>By posting content, you grant IT VISION SOFTWARE S.R.L. the right to use your name in connection with the posted materials if it wishes to, but it is not obliged to do so. We are not responsible for any content posted by you or by other third parties.</p>
            <p><br /></p>
            <p><a data-fr-linked="true" href="//www.itvisionsoftware.ro">www.itvisionsoftware.ro</a> has the right, but not the obligation, to monitor, edit, or delete user content or activities on the site. <a data-fr-linked="true" href="//www.itvisionsoftware.ro">www.itvisionsoftware.ro</a> does not assume responsibility for materials posted by third parties on the site and reserves the right not to publish all comments and articles proposed by users.</p>
            <p><strong><br /></strong></p>
            <p><strong>FORCE MAJEURE</strong></p>
            <p><br /></p>
            <p><a data-fr-linked="true" href="//www.itvisionsoftware.ro">www.itvisionsoftware.ro</a>, its affiliates, and/or suppliers of products or services to the online platform cannot be held responsible for delays or errors, either direct or indirect, caused by factors beyond the control of <a data-fr-linked="true" href="//www.itvisionsoftware.ro">www.itvisionsoftware.ro</a>. This exoneration includes, but is not limited to: errors in the operation of technical equipment, lack of internet connection, problems with telephone connections, computer viruses, unauthorized access to the systems of <a data-fr-linked="true" href="//www.itvisionsoftware.ro">www.itvisionsoftware.ro</a>, operational errors, as well as force majeure events provided by current legislation.</p>
            <p><br /></p>
            <p><strong>ADVERTISING</strong></p>
            <p><br /></p>
            <p><a data-fr-linked="true" href="//www.itvisionsoftware.ro">www.itvisionsoftware.ro</a> may display advertisements for its own products, as well as for products or services of third parties. By using <a data-fr-linked="true" href="//www.itvisionsoftware.ro">www.itvisionsoftware.ro</a> and accepting the Terms and Conditions of use, as well as the Privacy Policy, you express your agreement to receive such commercial communications.</p>
            <p><br /></p>
            <p><strong>DISPUTES</strong></p>
            <p><br /></p>
            <p>By viewing the products on this site, the user accepts that Romanian laws will govern the Terms and Conditions, as well as any disputes that may arise between the user and IT VISION SOFTWARE S.R.L.. In the event of conflicts, an attempt will be made for an amicable resolution within a minimum of 30 working days.</p>
            <p><br /></p>
            <p>If an amicable resolution is not possible, the dispute will be submitted to the decision of the competent court, according to the current Romanian legislation.</p>
            <p><br /></p>
            <p><strong>GENERAL TERMS AND CONDITIONS</strong></p>
            <p><br /></p>
            <p>The supplying company undertakes to provide correct, complete, and up-to-date information about the products presented on the site.</p>
            <p><br /></p>
            <p>The supplying company does not guarantee that <a data-fr-linked="true" href="//www.itvisionsoftware.ro">www.itvisionsoftware.ro</a>, its servers, or the emails sent do not contain errors, omissions, defects, delays, or interruptions in operation or transmission, line drops, or other similar factors inherent to the virtual environment.</p>
            <p><br /></p>
            <p>The supplying company disclaims responsibility for omissions or inaccuracies in drafting or presenting information on the site, including those due to operations that, for technical reasons, cannot be updated in real time.</p>
            <p><br /></p>
            <p>The user uses <a data-fr-linked="true" href="//www.itvisionsoftware.ro">www.itvisionsoftware.ro</a> at their own risk, and the supplying company is not responsible for direct or indirect damages caused by the use or access/visitation of the site or the use of presented information.</p>
            <p><br /></p>
            <p>The merchant, in its capacity as the recipient, acts at its own risk in a situation where it has been or should have been aware, in accordance with current business practices or procedures expressly agreed upon with the issuer, that the information in an electronic message has been altered during transmission or processing.</p>
            <p><br /></p>
            <p>None of the contracting parties can be held responsible for the non-performance (total/partial) or late performance of its obligations due to force majeure. The parties will immediately notify the situation and take all necessary measures to limit the consequences of the event.</p>
            <p><br /></p>
            <p>The supplying company is not responsible for losses caused by non-compliance with the Terms and Conditions or for damages resulting from the non-functioning of the site. The supplying company is not liable for damages of any kind suffered by the client or third parties as a result of the fulfillment of its obligations or the use of the products and services presented on the site.</p>
            <p><br /></p>
            <p><strong>MISCELLANEOUS</strong></p>
            <p><br /></p>
            <p>In the event that any of the above clauses is declared null or invalid, this does not affect the validity of the other clauses. By accepting the Terms and Conditions, the customer fully assumes the rights and obligations derived from visiting <a data-fr-linked="true" href="//www.itvisionsoftware.ro">www.itvisionsoftware.ro</a>.</p>
            <p><br /></p>
            <p><strong>CONTENT OF THE SITE</strong></p>
            <p><br /></p>
            <p><a data-fr-linked="true" href="//www.itvisionsoftware.ro">www.itvisionsoftware.ro</a> provides information about the services of IT VISION SOFTWARE S.R.L..</p>
            <p><br /></p>
            <p>All content is strictly informational. <a data-fr-linked="true" href="//www.itvisionsoftware.ro">www.itvisionsoftware.ro</a> cannot guarantee the correctness of the information and disclaims responsibility for its accuracy, integrity, or authenticity. This site and the information provided are provided without any warranty, express or implied.</p>
            <p><br /></p>
            <p><a data-fr-linked="true" href="//www.itvisionsoftware.ro">www.itvisionsoftware.ro</a> reserves the right to make changes at any time, without prior notice, regarding the specifications and accessibility of products and services.</p>
            <p><br /></p>
            <p>Any reference to commercial products or services, processes, or other information by mentioning the brand name, manufacturer, supplier, or other information does not constitute or imply endorsement or recommendation by <a data-fr-linked="true" href="//www.itvisionsoftware.ro">www.itvisionsoftware.ro</a>.</p>
            <p><br /></p>
            <p><strong>LIMITATION OF LIABILITY</strong></p>
            <p><br /></p>
            <p><a data-fr-linked="true" href="//www.itvisionsoftware.ro">www.itvisionsoftware.ro</a> will not be responsible for any direct, indirect, incidental, special, or other damages resulting from the use or inability to use the information or content on this site. If the use of this site requires service, repair, or correction of your equipment or data, you will assume all costs involved.</p>
            <p><br /></p>
            <p>For any additional information or clarification regarding the Terms and Conditions, please contact us at support@itvisionsoftware.ro.</p>
            <p><br /></p>
            <p><strong>Last update: February 29, 2024</strong></p>
              </Typography>
            )}
          </Box>
        </Paper>
      </Container>
    </motion.div>
  );
};

export default TermsAndConditions;
