import React, { useState, useRef, useEffect, useCallback } from 'react';
import { Box, Tooltip, CircularProgress } from '@mui/material';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

interface Image {
  src: string;
  name: string;
}

interface ProgressiveSliderProps {
  images: Image[];
  settings: any;
}

const ProgressiveSlider: React.FC<ProgressiveSliderProps> = ({ images, settings }) => {
  const [loadedImages, setLoadedImages] = useState<Set<number>>(new Set());
  const observerRef = useRef<IntersectionObserver | null>(null);
  const sliderRef = useRef<Slider | null>(null);

  // Function to handle image loading
  const handleImageLoad = (index: number) => {
    setLoadedImages(prev => new Set(prev).add(index));
  };

  // Observer callback to handle image lazy loading
  const observerCallback = useCallback((entries: IntersectionObserverEntry[]) => {
    entries.forEach(entry => {
      const index = Number(entry.target.getAttribute('data-index'));
      if (entry.isIntersecting && !loadedImages.has(index)) {
        const imgElement = entry.target.querySelector('img') as HTMLImageElement;
        if (imgElement) {
          const src = imgElement.dataset.src || '';
          imgElement.src = src; // Set the real src for lazy loading
          imgElement.onload = () => handleImageLoad(index); // Set handler for load
          imgElement.onerror = () => handleImageLoad(index); // Set handler for error
        }
      }
    });
  }, [loadedImages]);

  useEffect(() => {
    if (typeof window === 'undefined') return;

    const observer = new IntersectionObserver(observerCallback, {
      threshold: 0.1,
      rootMargin: '50px',
    });

    observerRef.current = observer;

    // Function to observe images
    const observeImages = () => {
      const elements = document.querySelectorAll('.image-container');
      elements.forEach(el => observer.observe(el));
    };

    observeImages();

    return () => {
      observerRef.current?.disconnect();
    };
  }, [observerCallback]);

  const handleAfterChange = (currentSlide: number) => {
   // console.log(`Slide changed to: ${currentSlide}`);
    // Optionally, you can trigger image loading on slide change
    const elements = document.querySelectorAll('.image-container');
    elements.forEach(el => {
      const index = Number(el.getAttribute('data-index'));
      if (!loadedImages.has(index)) {
        observerRef.current?.observe(el);
      }
    });
  };

  return (
    <Box className="ContainerImaginiLimbaje" sx={{ position: 'relative', maxWidth: '100%', paddingTop: 5, paddingBottom: 5}}>


      <Slider {...settings} ref={sliderRef} afterChange={handleAfterChange}>
        {images.map((image, index) => (
          <div
            className="image-container"
            data-index={index}
            key={index}
            style={{ position: 'relative', width: '160px', height: '260px' }}
          >
            <Tooltip arrow className="tooltip-slider" title={image.name} placement="top">
              <div className='imagineSliderLimbaje' style={{ position: 'relative', width: '130px', height: '200px', margin: 'auto', marginTop: '30px' }}>
                {!loadedImages.has(index) && (
                  <CircularProgress
                    size={60}
                    sx={{ position: 'absolute', top: '30%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 1 }}
                  />
                )}
                <img
                  data-src={`imagini-limbaje/${image.src}`} // Use data-src for lazy loading
                 
                  style={{
                    width: '130px',
                    height: 'auto',
                    objectFit: 'cover',
                    
                    margin: 'auto',
                    borderRadius: '5px',
                    transition: 'opacity 0.5s ease-in-out',
                    opacity: loadedImages.has(index) ? 1 : 0, // Hide image until it is loaded
                    position: 'relative',
                    zIndex: 2
                  }}
                  alt={image.name}
                  src={loadedImages.has(index) ? `imagini-limbaje/${image.src}` : ''} // Load image only if it has been intersected
                />
              </div>
            </Tooltip>
          </div>
        ))}
      </Slider>
    </Box>
  );
};

export default ProgressiveSlider;
