import React, { useState, useEffect } from 'react';
import { Box, Typography, Button, Switch, FormControlLabel, Tabs, Tab, IconButton, Tooltip, Collapse, Grid } from '@mui/material';
import Cookies from 'js-cookie';
import { TabContext, TabPanel } from '@mui/lab';
import CookieIcon from '@mui/icons-material/Cookie';
import { gsap } from 'gsap';
import ReactGA from 'react-ga4'; // Import react-ga4

type CookiePreferences = {
  necessary: boolean;
  analytics: boolean;
  marketing: boolean;
};

const defaultPreferences: CookiePreferences = {
  necessary: true,
  analytics: false,
  marketing: false,
};

const CookieConsent: React.FC = () => {
  const [preferences, setPreferences] = useState<CookiePreferences>(defaultPreferences);
  const [language, setLanguage] = useState('en');
  const [showConsent, setShowConsent] = useState(false);
  const [showIcon, setShowIcon] = useState(true);
  const [expanded, setExpanded] = useState<Record<string, boolean>>({
    analytics: false,
    marketing: false,
  });

  useEffect(() => {
    const savedPreferences = Cookies.get('cookiePreferences');
    
    if (savedPreferences) {
      try {
        const parsedPreferences: CookiePreferences = JSON.parse(savedPreferences);
        if (isValidPreferences(parsedPreferences)) {
          setPreferences(parsedPreferences);
          setShowConsent(false);
          setShowIcon(true); // Hide the icon if preferences are saved
          if (parsedPreferences.analytics) {
            ReactGA.initialize('G-G2R1TV3C17'); // Replace with your GA tracking ID
            ReactGA.send({ hitType: 'pageview', page: window.location.pathname + window.location.search });
          }
        } else {
          console.warn('Invalid cookie data. Showing consent.');
          setShowConsent(true);
          setShowIcon(true); // Show the icon if cookie data is invalid
        }
      } catch (e) {
        console.error('Error parsing JSON. Showing consent.', e);
        setShowConsent(true);
        setShowIcon(true); // Show the icon if there's a parsing error
      }
    } else {
      setShowConsent(true); // Show consent if no saved cookies
      setShowIcon(true); // Show the icon if no saved cookies
    }
  }, []);

  useEffect(() => {
    if (showConsent) {
      gsap.to('.cookie-consent', {
        opacity: 1,
        y: 0,
        duration: 0.5,
        ease: 'power2.out',
        visibility: 'visible'
      });
      gsap.to('.cookie-icon', {
        opacity: 0,
        x: 50, // Hide the icon
        duration: 0.5,
        ease: 'power2.in'
      });
    } else {
      gsap.to('.cookie-consent', {
        opacity: 0,
        y: 100,
        duration: 0.5,
        ease: 'power2.in',
        visibility: 'hidden'
      });
      gsap.to('.cookie-icon', {
        opacity: 1,
        x: 0, // Show the icon
        duration: 0.5,
        ease: 'power2.out'
      });
    }
  }, [showConsent]);

  useEffect(() => {
    if (showIcon) {
      gsap.fromTo('.cookie-icon',
        { opacity: 0, x: -50, scale: 0.8 },
        { opacity: 1, x: 0, scale: 1, duration: 0.5, ease: 'power2.out' }
      );
    } else {
      gsap.to('.cookie-icon',
        { opacity: 0, x: -50, scale: 0.8, duration: 0.5, ease: 'power2.out' }
      );
    }
  }, [showIcon]);

  const isValidPreferences = (prefs: any): prefs is CookiePreferences => {
    return prefs && typeof prefs.necessary === 'boolean' && typeof prefs.analytics === 'boolean' && typeof prefs.marketing === 'boolean';
  };

  const handleToggle = (type: keyof CookiePreferences) => {
    setPreferences((prev) => ({ ...prev, [type]: !prev[type] }));
  };

  const handleExpand = (type: string) => {
    setExpanded((prev) => ({ ...prev, [type]: !prev[type] }));
  };

  const handleAcceptAll = () => {
    const allPreferences = { necessary: true, analytics: true, marketing: true };
    setPreferences(allPreferences);
    savePreferences(allPreferences);
    setShowConsent(false);
    ReactGA.initialize('G-G2R1TV3C17'); // Replace with your GA tracking ID
    ReactGA.send({ hitType: 'pageview', page: window.location.pathname + window.location.search });
  };

  const handleRejectAll = () => {
    const noPreferences = { necessary: true, analytics: false, marketing: false };
    setPreferences(noPreferences);
    savePreferences(noPreferences);
    setShowConsent(false);
  };

  const handleSavePreferences = () => {
    savePreferences(preferences);
    setShowConsent(false);
    if (preferences.analytics) {
      ReactGA.initialize('G-G2R1TV3C17'); // Replace with your GA tracking ID
      ReactGA.send({ hitType: 'pageview', page: window.location.pathname + window.location.search });
    }
  };

  const savePreferences = (prefs: CookiePreferences) => {
    Cookies.set('cookiePreferences', JSON.stringify(prefs), { expires: 120, secure: true, sameSite: 'Strict' });
  };

  const handleChangeLanguage = (event: React.SyntheticEvent, newValue: string) => {
    setLanguage(newValue);
  };

  const content = {
    en: {
      title: 'Cookie Preferences',
      description: 'We use cookies to enhance your experience. Select which cookies you want to accept.',
      necessary: 'Necessary Cookies (Always enabled)',
      analytics: 'Analytics Cookies',
      marketing: 'Marketing Cookies',
      acceptAll: 'Accept All Cookies',
      rejectAll: 'Reject All Cookies Except Necessary',
      savePreferences: 'Save Preferences',
      necessaryDesc: 'These cookies are essential for the website to function and cannot be turned off.',
      analyticsDesc: 'These cookies help us understand how visitors interact with our site.',
      marketingDesc: 'These cookies are used to deliver ads that are relevant to you.',
    },
    ro: {
      title: 'Preferințe cookie',
      description: 'Folosim cookie-uri pentru a îmbunătăți experiența dvs. Selectați ce cookie-uri doriți să acceptați.',
      necessary: 'Cookie-uri necesare (Întotdeauna activat)',
      analytics: 'Cookie-uri de analiză',
      marketing: 'Cookie-uri de marketing',
      acceptAll: 'Acceptă toate cookie-urile',
      rejectAll: 'Refuză toate cookie-urile, cu excepția celor necesare',
      savePreferences: 'Salvează preferințele',
      necessaryDesc: 'Aceste cookie-uri sunt esențiale pentru funcționarea site-ului și nu pot fi dezactivate.',
      analyticsDesc: 'Aceste cookie-uri ne ajută să înțelegem cum interacționează vizitatorii cu site-ul nostru.',
      marketingDesc: 'Aceste cookie-uri sunt utilizate pentru a livra anunțuri relevante pentru tine.',
    }
  };

  const currentContent = content[language as keyof typeof content];

  return (
    <>
      <Box
        className="cookie-consent"
        sx={{
          position: 'fixed',
          bottom: 0,
          left: '50%',
          transform: 'translateX(-50%)',
          width: '90%',
          maxWidth: '600px',
          backgroundColor: 'rgba(255, 255, 255, 0.8)',
          color: '#333',
          p: 3,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          boxShadow: '0px 2px 15px rgba(0, 0, 0, 0.2)',
          borderTopLeftRadius: '12px',
          borderTopRightRadius: '12px',
          zIndex: 999999999,
          backdropFilter: 'blur(10px)',
          transition: 'visibility 0.3s ease, opacity 0.3s ease',
          visibility: showConsent ? 'visible' : 'hidden',
          opacity: showConsent ? 1 : 0,
        }}
      >
        <Typography variant="h6" sx={{ mb: 2, fontWeight: 'bold', textAlign: 'center' }}>{currentContent.title}</Typography>
        <Typography variant="body1" sx={{ mb: 2, textAlign: 'center' }}>{currentContent.description}</Typography>
        <TabContext value={language}>
          <Tabs
            value={language}
            onChange={handleChangeLanguage}
            centered
            sx={{ borderBottom: 1, borderColor: 'divider', mb: 2, backgroundColor: '#f5f5f5', borderRadius: '8px' }}
          >
            <Tab label="English" value="en" />
            <Tab label="Română" value="ro" />
          </Tabs>
          <TabPanel value="en">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormControlLabel
                  control={<Switch checked={preferences.necessary} disabled />}
                  label={currentContent.necessary}
                />
                <Typography variant="body2" sx={{ mb: 1 }}>
                  <Button
                    onClick={() => handleExpand('necessary')}
                    sx={{ textTransform: 'none', color: 'blue' }}
                  >
                    {expanded.necessary ? 'Hide Information' : 'See Information'}
                  </Button>
                </Typography>
                <Collapse in={expanded.necessary}>
                  <Typography variant="body2" sx={{ mb: 1 }}>{currentContent.necessaryDesc}</Typography>
                </Collapse>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControlLabel
                  control={<Switch checked={preferences.analytics} onChange={() => handleToggle('analytics')} />}
                  label={currentContent.analytics}
                />
                <Typography variant="body2" sx={{ mb: 1 }}>
                  <Button
                    onClick={() => handleExpand('analytics')}
                    sx={{ textTransform: 'none', color: 'blue' }}
                  >
                    {expanded.analytics ? 'Hide Information' : 'See Information'}
                  </Button>
                </Typography>
                <Collapse in={expanded.analytics}>
                  <Typography variant="body2" sx={{ mb: 1, fontWeight: 'bold' }}>Analytics Cookies</Typography>
                  <Typography variant="body2" sx={{ mb: 1 }}>{currentContent.analyticsDesc}</Typography>
                </Collapse>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControlLabel
                  control={<Switch checked={preferences.marketing} onChange={() => handleToggle('marketing')} />}
                  label={currentContent.marketing}
                />
                <Typography variant="body2" sx={{ mb: 1 }}>
                  <Button
                    onClick={() => handleExpand('marketing')}
                    sx={{ textTransform: 'none', color: 'blue' }}
                  >
                    {expanded.marketing ? 'Hide Information' : 'See Information'}
                  </Button>
                </Typography>
                <Collapse in={expanded.marketing}>
                  <Typography variant="body2" sx={{ mb: 1, fontWeight: 'bold' }}>Marketing Cookies</Typography>
                  <Typography variant="body2" sx={{ mb: 1 }}>{currentContent.marketingDesc}</Typography>
                </Collapse>
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value="ro">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormControlLabel
                  control={<Switch checked={preferences.necessary} disabled />}
                  label={currentContent.necessary}
                />
                <Typography variant="body2" sx={{ mb: 1 }}>
                  <Button
                    onClick={() => handleExpand('necessary')}
                    sx={{ textTransform: 'none', color: 'blue' }}
                  >
                    {expanded.necessary ? 'Ascunde informațiile' : 'Vezi informațiile'}
                  </Button>
                </Typography>
                <Collapse in={expanded.necessary}>
                  <Typography variant="body2" sx={{ mb: 1 }}>{currentContent.necessaryDesc}</Typography>
                </Collapse>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControlLabel
                  control={<Switch checked={preferences.analytics} onChange={() => handleToggle('analytics')} />}
                  label={currentContent.analytics}
                />
                <Typography variant="body2" sx={{ mb: 1 }}>
                  <Button
                    onClick={() => handleExpand('analytics')}
                    sx={{ textTransform: 'none', color: 'blue' }}
                  >
                    {expanded.analytics ? 'Ascunde informațiile' : 'Vezi informațiile'}
                  </Button>
                </Typography>
                <Collapse in={expanded.analytics}>
                  <Typography variant="body2" sx={{ mb: 1, fontWeight: 'bold' }}>Cookie-uri de analiză</Typography>
                  <Typography variant="body2" sx={{ mb: 1 }}>{currentContent.analyticsDesc}</Typography>
                </Collapse>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControlLabel
                  control={<Switch checked={preferences.marketing} onChange={() => handleToggle('marketing')} />}
                  label={currentContent.marketing}
                />
                <Typography variant="body2" sx={{ mb: 1 }}>
                  <Button
                    onClick={() => handleExpand('marketing')}
                    sx={{ textTransform: 'none', color: 'blue' }}
                  >
                    {expanded.marketing ? 'Ascunde informațiile' : 'Vezi informațiile'}
                  </Button>
                </Typography>
                <Collapse in={expanded.marketing}>
                  <Typography variant="body2" sx={{ mb: 1, fontWeight: 'bold' }}>Cookie-uri de marketing</Typography>
                  <Typography variant="body2" sx={{ mb: 1 }}>{currentContent.marketingDesc}</Typography>
                </Collapse>
              </Grid>
            </Grid>
          </TabPanel>
        </TabContext>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 2, width: '100%' }}>
          <Button onClick={handleRejectAll} variant="contained" sx={{ backgroundColor: '#007bff', color: '#fff' }}>
            {currentContent.rejectAll}
          </Button>
          <Button onClick={handleAcceptAll} variant="contained" sx={{ backgroundColor: '#007bff', color: '#fff' }}>
            {currentContent.acceptAll}
          </Button>
          <Button onClick={handleSavePreferences} variant="contained" sx={{ backgroundColor: '#007bff', color: '#fff' }}>
            {currentContent.savePreferences}
          </Button>
        </Box>
      </Box>

      {showIcon && !showConsent && (
        <Tooltip title="Cookie Preferences" arrow>
          <IconButton
            className="cookie-icon"
            onClick={() => setShowConsent(true)}
            sx={{
              position: 'fixed',
              bottom: 16,
              left: 16,
              backgroundColor: '#ffffff',
              color: '#000000',
              boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.2)',
              '&:hover': { backgroundColor: '#f0f0f0' },
              zIndex: 10000,
              transition: 'background-color 0.3s ease',
            }}
          >
            <CookieIcon sx={{ color: 'dodgerblue' }} />
          </IconButton>
        </Tooltip>
      )}
    </>
  );
};

export default CookieConsent;
