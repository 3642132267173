import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Chip from '@mui/material/Chip';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import { Fade } from 'react-awesome-reveal';
import { NavLink } from 'react-router-dom';

const basePackages = [
  {
    title: 'StartUp',
    price: '650',
    description: [
      'Landing page simplu',
      'Până la 3 pagini',
      'Design personalizat',
      'Consultanță gratuită',
      'Suport tehnic limitat',
      'Răspuns rapid la emailuri',
      'Integrare social media',
      'Optimizare SEO de bază',
    ],
    buttonText: 'Contact',
    buttonVariant: 'outlined',
  },
  {
    title: 'Professional',
    subheader: 'Recomandat',
    price: '1450',
    description: [
      'Design modern personalizat',
      'Consultanță SEO avansată',
      'Suport tehnic prioritar',
      'Actualizări periodice incluse',
      'Optimizare mobilă avansată',
      'Integrare Google Analytics',
      'Animatii avansate cu GSAP',
      'Formulare personalizate avansate',
      'Integrare servicii externe',
    ],
    buttonText: 'Contact',
    buttonVariant: 'contained',
  },
  {
    title: 'Enterprise',
    price: '4200',
    description: [
      'Platformă completă personalizată',
      'Funcționalități avansate',
      'Consultanță completă dezvoltare',
      'Suport dedicat și training',
      'Integrări complexe API-uri',
      'FrontEnd: ReactJs',
      'Backend: .NET Core (C#)',
      'Backend: Laravel (PHP)',
      'Scalabilitate și flexibilitate',
    ],
    buttonText: 'Contact',
    buttonVariant: 'outlined',
  },
];

const customSolutions = [
  {
    title: 'SPA dev',
    description: [
      'Dezvoltare aplicații SPA',
      'Performanță ridicată garantată',
      'Funcționalități avansate SPA',
      'Consultanță arhitectură software',
      'Suport dedicat pe termen lung',
      'Integrare API-uri externe',
      'Utilizare React Hooks',
      'Context API și Vuex',
      'Gestionare stări avansate',
    ],
    buttonText: 'Contact',
    buttonVariant: 'outlined',
  },
  {
    title: 'ERP',
    description: [
      'Soluție ERP personalizată',
      'Gestionare resurse avansată',
      'Funcționalități de management',
      'Integrare CRM și POS',
      'Consultanță și implementare',
      'Suport tehnic dedicat',
      'Actualizări regulate incluse',
      'Utilizare .NET Core/Laravel',
      'Sistem scalabil și robust',
    ],
    buttonText: 'Contact',
    buttonVariant: 'outlined',
  },
  {
    title: 'CRM',
    description: [
      'Sistem CRM complet',
      'Gestionare clienți eficientă',
      'Automatizare procese vânzări',
      'Raportare și analize personalizate',
      'Integrare ERP și e-commerce',
      'Suport tehnic continuu',
      'Actualizări incluse regulat',
      'Performanță și securitate',
      'Tehnologii moderne utilizate',
    ],
    buttonText: 'Contact',
    buttonVariant: 'outlined',
  },
  {
    title: 'API DEV',
    description: [
      'Dezvoltare API personalizată',
      'Documentație completă API',
      'Testare performanță riguroasă',
      'Consultanță tehnică API',
      'Optimizare scalabilitate',
      'Suport post-lansare',
      'Utilizare GraphQL/RESTful APIs',
      'Integrare ușoară cu sisteme',
      'Arhitectură modernă',
    ],
    buttonText: 'Contact',
    buttonVariant: 'outlined',
  },
  {
    title: 'E-commerce',
    description: [
      'Platformă e-commerce personalizată',
      'Design atractiv și funcțional',
      'Integrare soluții plată',
      'Management stocuri și produse',
      'Optimizare SEO produse',
      'Suport tehnic continuu',
      'Actualizări regulate incluse',
      'Scalabilitate și flexibilitate',
      'Răspuns rapid la probleme',
    ],
    buttonText: 'Contact',
    buttonVariant: 'outlined',
  },
  {
    title: 'Custom Software',
    description: [
      'Dezvoltare software personalizat',
      'Consultanță detaliată proiect',
      'Implementare și integrare',
      'Testare și suport tehnic',
      'Scalabilitate viitoare cerințe',
      'Utilizare tehnologii moderne',
      'Bune practici dezvoltare',
      'Arhitectură adaptabilă',
      'Suport continuu dedicat',
    ],
    buttonText: 'Contact',
    buttonVariant: 'outlined',
  },
];

export default function Pricing() {
  return (
    <Container
      id="pricing"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: { xs: 3, sm: 6 },
      }}
    >
      <Box
        sx={{
          width: { sm: '100%', md: '60%' },
          textAlign: { sm: 'left', md: 'center' },
        }}
      >
        <Fade cascade triggerOnce>
          <Typography
            variant="h2"
            component="h1"
            gutterBottom
            sx={{
              fontWeight: 700,
              fontSize: { xs: '1.8rem', sm: '2.5rem', md: '3rem', lg: '4rem' },
              color: 'black',
            }}
          >
            Ofertele Noastre
          </Typography>
          <Typography
            variant="body1"
            color="text.secondary"
            sx={{ fontSize: '1.2rem', mb: 3 }}
          >
            Cele mai bune oferte pentru a ne asigura că îți atingi obiectivele! <br />
            De la consultanță la finalizarea proiectului, suntem partenerii tăi de încredere în fiecare pas al drumului.
          </Typography>
        </Fade>
      </Box>
      <Typography
        variant="h4"
        component="h2"
        gutterBottom
        sx={{
          fontWeight: 700,
          fontSize: '2rem',
          mb: 4,
          color: 'black',
        }}
      >
        Pachete de bază
      </Typography>
      <Grid container spacing={3} alignItems="center" justifyContent="center">
        {basePackages.map((tier) => (
          <Grid
            item
            key={tier.title}
            xs={12}
            sm={tier.title === 'Enterprise' ? 12 : 6}
            md={4}
          >
            <Fade cascade damping={1e-1}>
              <Card
                sx={{
                  borderRadius: 5,
                  p: 2,
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 4,
                  boxShadow: '0px 4px 12px rgba(0,0,0,0.1)',
                  border: tier.title === 'Professional' ? '3px solid' : '3px solid rgba(0,0,0,0.1)',
                  borderColor: tier.title === 'Professional' ? 'primary.main' : undefined,
                  background: tier.title === 'Professional'
                    ? 'linear-gradient(166deg, rgba(1,16,79,1) 0%, rgba(11,76,148,1) 100%)'
                    : undefined,
                }}
              >
                <CardContent sx={{ textAlign: 'left' }}>
                  <Box
                    sx={{
                      mb: 1,
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      color: tier.title === 'Professional' ? 'grey.100' : '',
                    }}
                  >
                    <Typography
                      component="h3"
                      variant="h5"
                      sx={{ fontWeight: 'bold' }}
                    >
                      {tier.title}
                    </Typography>
                    {tier.title === 'Professional' && (
                      <Chip
                        icon={<AutoAwesomeIcon />}
                        label={tier.subheader}
                        size="small"
                        sx={{
                          background: (theme) =>
                            theme.palette.mode === 'light' ? '' : 'none',
                          backgroundColor: 'primary.contrastText',
                          '& .MuiChip-label': {
                            color: 'primary.dark',
                          },
                          '& .MuiChip-icon': {
                            color: 'primary.dark',
                          },
                        }}
                      />
                    )}
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'baseline',
                      color: tier.title === 'Professional' ? 'grey.50' : undefined,
                      fontWeight: 'bold',
                    }}
                  >
                    <Typography component="h4" variant="h4" sx={{ fontWeight: '800' }}>
                      {tier.price} RON
                    </Typography>
                    <Typography variant="body1">
                      &nbsp;&nbsp; <s>&nbsp; {Number(tier.price) + 250} RON &nbsp; </s>
                    </Typography>
                  </Box>
                  <Divider
                    sx={{
                      my: 2,
                      opacity: 0.2,
                      borderColor: 'grey.500',
                    }}
                  />
                  {tier.description.slice(0, 8).map((line) => (
                    <Box
                      key={line}
                      sx={{
                        py: 1,
                        display: 'flex',
                        gap: 1.5,
                        alignItems: 'center',
                      }}
                    >
                      <CheckCircleRoundedIcon
                        sx={{
                          width: 20,
                          color: tier.title === 'Professional' ? 'primary.light' : 'primary.main',
                        }}
                      />
                      <Typography
                        component="span"
                        variant="subtitle2"
                        sx={{
                          color: tier.title === 'Professional' ? 'grey.200' : undefined,
                          fontWeight: 'bold',
                        }}
                      >
                        {line}
                      </Typography>
                    </Box>
                  ))}
                </CardContent>
                <CardActions>
                  <Button
                    fullWidth
                    variant={tier.buttonVariant as 'outlined' | 'contained'}
                    component={NavLink}
                    to="/contact"
                  >
                    {tier.buttonText}
                  </Button>
                </CardActions>
              </Card>
            </Fade>
          </Grid>
        ))}
      </Grid>
      <Typography
        variant="h4"
        component="h2"
        gutterBottom
        sx={{
          fontWeight: 700,
          fontSize: '2rem',
          mt: 6,
          mb: 4,
          color: 'black',
        }}
      >
        Soluții Personalizate
      </Typography>
      <Grid container spacing={3} alignItems="center" justifyContent="center">
        {customSolutions.map((solution) => (
          <Grid
            item
            key={solution.title}
            xs={12}
            sm={6}
            md={4}
          >
            <Fade cascade damping={1e-1}>
              <Card
                sx={{
                  borderRadius: 5,
                  p: 2,
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 4,
                  boxShadow: '0px 4px 12px rgba(0,0,0,0.1)',
                  border: '3px solid rgba(0,0,0,0.1)',
                }}
              >
                <CardContent sx={{ textAlign: 'left' }}>
                  <Box
                    sx={{
                      mb: 1,
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <Typography
                      component="h3"
                      variant="h5"
                      sx={{ fontWeight: 'bold', color: 'black' }}
                    >
                      {solution.title}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'baseline',
                      fontWeight: 'bold',
                    }}
                  >
                    <Typography variant="body1" sx={{ color: 'text.secondary' }}>
                      Contactați-ne pentru detalii
                    </Typography>
                  </Box>
                  <Divider
                    sx={{
                      my: 2,
                      opacity: 0.2,
                      borderColor: 'grey.500',
                    }}
                  />
                  {solution.description.slice(0, 8).map((line) => (
                    <Box
                      key={line}
                      sx={{
                        py: 1,
                        display: 'flex',
                        gap: 1.5,
                        alignItems: 'center',
                      }}
                    >
                      <CheckCircleRoundedIcon
                        sx={{
                          width: 20,
                          color: 'primary.main',
                        }}
                      />
                      <Typography
                        component="span"
                        variant="subtitle2"
                        sx={{
                          fontWeight: 'bold',
                        }}
                      >
                        {line}
                      </Typography>
                    </Box>
                  ))}
                </CardContent>
                <CardActions>
                  <Button
                    fullWidth
                    variant={solution.buttonVariant as 'outlined' | 'contained'}
                    component={NavLink}
                    to="/contact"
                  >
                    {solution.buttonText}
                  </Button>
                </CardActions>
              </Card>
            </Fade>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}
