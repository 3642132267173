import React, { useRef, useState, useEffect, useMemo} from 'react';
import { Container, Typography, Box, Grid, Skeleton, Card, CardContent ,Tooltip ,useTheme, useMediaQuery , TextField, Button, Divider} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { motion } from 'framer-motion';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { useInView } from 'react-intersection-observer';
import Slider, { Settings } from 'react-slick';
import 'slick-carousel/slick/slick.css'; // Import slick carousel styles
import 'slick-carousel/slick/slick-theme.css'; // Import slick carousel theme styles
import { styled } from '@mui/system';
import SendIcon from '@mui/icons-material/Send';
import FireworksComponent from '../Componente/FireworksComponent';
import { Fade,Slide, Bounce } from "react-awesome-reveal";
import {
  Assignment as AssignmentIcon,
  DesignServices as GraphicDesignIcon,
  Search as SearchIcon,
  Security as SecurityIcon,
  Dashboard as DashboardIcon,
  BarChart as BarChartIcon,
  Apps as AppsIcon,
  SettingsInputComponent as SettingsInputComponentIcon,
  Edit as EditIcon,
  Speed as SpeedIcon,
  Lock as LockIcon,
  Backup as BackupIcon,
  Addchart as ChartIcon,
  Construction as ConstructionIcon,
  VoiceChat as VoiceIcon,
Star as Star,
Info as InfoIcon,
CheckCircleOutline as Bifa,
Insights as Modern

} from '@mui/icons-material';
import ParallaxComponent from '../ParallaxComponent';
import ProgressiveSlider from '../ProgressiveSlider';



gsap.registerPlugin(ScrollTrigger);

const Acasa: React.FC = () => {
  const liniiRef = useRef<HTMLDivElement[]>([]);
  const procenteRef = useRef<HTMLSpanElement[]>([]);
  const procenteFinale = [20, 40, 60, 80, 100]; // Valorile finale pentru procente
  const [loading, setLoading] = useState(true); // State pentru gestionarea stării de încărcare
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [showFireworks, setShowFireworks] = useState(false);
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [positions, setPositions] = useState([]);
  const [isAnimating, setIsAnimating] = useState(false);
  const [loadedImages, setLoadedImages] = useState<Set<number>>(new Set());
  const observerRef = useRef<IntersectionObserver | null>(null);

  
  // Hook pentru Intersection Observer pentru a monitoriza când intră în viewport
  const observerOptions = useMemo(() => ({
    triggerOnce: true,
  }), []);
  
  const [textContainerRef, inView] = useInView(observerOptions);

  useEffect(() => {
    // Simulare întârziere încărcare date
    const timer = setTimeout(() => {
      setLoading(false);
    }, 700); // Ajustați întârzierea după necesitate

    return () => clearTimeout(timer); // Curățare timer la demontare
  }, []);


  useEffect(() => {
    // Selectează elementul după ID și aplică scroll
    const element = document.getElementById('scroll-Acasa');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);
  useEffect(() => {
    if (!loading) {
      liniiRef.current.forEach((el, index) => {
        gsap.fromTo(
          el,
          { height: '0%' },
          {
            height: `${el.dataset.height}%`,
            duration: 1.5,
            ease: 'power2.out',
            scrollTrigger: {
              trigger: "#grafic-section",
              start: 'top 60%',
              toggleActions: 'play none none reverse',
            },
          }
        );
      });

      procenteRef.current.forEach((el, index) => {
        gsap.to(el, {
          textContent: `${procenteFinale[index]}`,
          duration: 1.5,
          onUpdate: () => {
            el.textContent = Math.ceil(parseFloat(el.textContent || '0')).toString();
          },
          scrollTrigger: {
            trigger: "#grafic-section",
            start: 'top 80%',
            toggleActions: 'play none none reverse',
          },
        });
      });
    }
  }, [loading]);

  // Animation for dev-stanga
  useEffect(() => {
  
      gsap.to('#dev-stanga', {
        left: isMobile ? '65%' : '60%',
        duration: 1.5,
        ease: 'power2.out',
        scrollTrigger: {
          trigger: "#dev-stanga",
          start: 'top 90%',
          end: 'bottom 20%',
          scrub: true,
          toggleActions: 'play none none reverse',
        },
      });
    
  });


  useEffect(() => {
    if (inView) {
      gsap.to('.LoginContainer ', {
        opacity: 1,
        transform:'translateY(0)',
        duration: 0.7,
        ease: 'power1.out',
        scrollTrigger: {
          trigger: ".LoginContainer",
          start: 'top 70%',
          scrub: false,
          toggleActions: 'play none none none',
        },
      });
    }
  }, [inView, isMobile]);




  useEffect(() => {
    if (inView) {
      for (let i = 1; i < 5; i++) {
        gsap.from(`#textpas${i}`, {
          opacity: 0,
          x: -200, // Slide in from the left
          duration: 1,
          scrollTrigger: {
            trigger: `#textpas${i}`,
            start: "top 80%", // Adjust this as needed
            end: "bottom 50%", // Adjust this as needed
            scrub: true,
      
          }
        });
      }
    }
  }, [inView]);


  useEffect(() => {
    if (inView) {
      for (let i = 1; i < 5; i++) {
        gsap.from(`#ContainerTextPasi${i}`, {
          opacity:0,
          x: 200, // Slide in from the left
          duration: 0.3,
          scrollTrigger: {
            trigger: `#ContainerTextPasi${i}`,
            start: "top 90%", // Adjust this as needed
            end: "bottom 10%",
      
          }
        });
      }
    }
  }, [inView]);


   
  useEffect(() => {
    if (inView) {
      for (let i = 1; i < 5; i++) {
        gsap.from(`.linieSectiuneSplitPas${i}`, {
          opacity: 0,
          y: 230, // Slide in from the left
          duration: 1.5,
          scrollTrigger: {
            trigger: `#subtextpas${i}`,
            start: "top 80%", // Adjust this as needed
            end: "bottom 50%", // Adjust this as needed
            scrub: true,
      
          }
        });
      }
    }
  }, [inView]);

  useEffect(() => {
    if (inView) {
      for (let i = 1; i < 5; i++) {
        gsap.from(`#subtextpas${i}`, {
          opacity: 0,
          x: -200, // Slide in from the left
          duration: 1,
          scrollTrigger: {
            trigger: `#subtextpas${i}`,
            start: "top 80%", // Adjust this as needed
            end: "bottom 60%", // Adjust this as needed
            scrub: true,
      
          }
        });
      }
    }
  }, [inView]);





  const routeVariants = {
    initial: {
      opacity: 0,
      y: 20,
      scale: 0.8,
    },
    final: {
      opacity: 1,
      y: 0,
      scale: 1,
      transition: {
        duration: 0.5,
        ease: 'easeInOut',
      },
    },
  };


  const handleLoginClick = () => {
    // Clear input fields
    setUsername('');
    setPassword('');

    // Show fireworks
    setShowFireworks(true);

    // Hide fireworks after 3 seconds
    setTimeout(() => {
      setShowFireworks(false);
    }, 3000);
  };

  
const images = [
  { src: 'apache-original.svg', name: 'Apache' },
  { src: 'bash-original.svg', name: 'Bash' },
  { src: 'bootstrap-original.svg', name: 'Bootstrap' },
  { src: 'css3-original.svg', name: 'CSS3' },
  { src: 'dotnetcore-original.svg', name: '.NET Core' },
  { src: 'github-original.svg', name: 'GitHub' },
  { src: 'html5-original.svg', name: 'HTML5' },
  { src: 'json-original.svg', name: 'JSON' },
  { src: 'materialui-original.svg', name: 'Material UI' },
  { src: 'mysql-original.svg', name: 'MySQL' },
  { src: 'nextjs-original.svg', name: 'Next.js' },
  { src: 'npm-original-wordmark.svg', name: 'NPM' },
  { src: 'nuget-original.svg', name: 'NuGet' },
  { src: 'php-original.svg', name: 'PHP' },
  { src: 'react-original.svg', name: 'React' },
  { src: 'sqldeveloper-original.svg', name: 'SQL Developer' },
  { src: 'swagger-original.svg', name: 'Swagger' },
  { src: 'tailwindcss-original.svg', name: 'Tailwind CSS' },
  { src: 'visualstudio-original.svg', name: 'Visual Studio' },
  { src: 'vuejs-original.svg', name: 'Vue.js' },
  { src: 'woocommerce-original.svg', name: 'WooCommerce' },
  { src: 'wordpress-original.svg', name: 'WordPress' },
  { src: 'powershell-original.svg', name: 'Powershell' },
];


useEffect(() => {
  if (typeof window === 'undefined') return;
  
  const observer = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        const index = Number(entry.target.getAttribute('data-index'));
        setLoadedImages((prev) => new Set(prev).add(index));
        observer.unobserve(entry.target);
      }
    });
  }, { threshold: 0.1 });

  observerRef.current = observer;

  const elements = document.querySelectorAll('.image-container');
  elements.forEach((el) => observer.observe(el));

  return () => observer.disconnect();
}, []);

  const linii = [
    { inaltime: 20 },
    { inaltime: 40 },
    { inaltime: 60 },
    { inaltime: 80 },
    { inaltime: 100 },
  ];

const AnimatieCarucior = React.memo(() => {
  return (
    <div className="ContainerAnimatieCarucior">
      <div className="backgroundAnimatieCarucior"></div>
      <img className="reveal-item-img" id="carucior" src="imagini/carucior.svg" alt="Carucior" />
      <img className="reveal-item-img" id="dev-stanga" src="imagini/dev-stanga.svg" alt="Dev Stanga" />
      <img className="reveal-item-img" id="client" src="imagini/client.svg" alt="Client" />
      <img className="reveal-item-img" id="cutii" src="imagini/cutii.svg" alt="Cutii" />
    </div>
  );
});
  // Settings for the react-slick Slider component
  const settings: Settings = {
    dots: false,
    infinite: true,
    speed: 500, // Increase speed for smoother transitions
    slidesToShow: isMobile ? 2 : 4,
    slidesToScroll: 1,
    autoplay: true,
    swipeToSlide: true,
    autoplaySpeed: 2000, // Controls the delay between slides
    lazyLoad: 'ondemand', // Lazy load images on demand
    pauseOnHover: true, // Pause autoplay on hover
  };




  const steps = [
    { title: 'Step 1: Contact Us', description: 'Get in touch with us through our contact form or give us a call.' },
    { title: 'Step 2: Consultation', description: 'Schedule a consultation with our experts to discuss your needs.' },
    { title: 'Step 3: Proposal', description: 'We provide a detailed proposal tailored to your requirements.' },
    { title: 'Step 4: Implementation', description: 'Our team starts working on your project, ensuring timely delivery.' },
  ];

  const cardsData = [
    {
      title: 'Consultanță',
      description: 'Oferim consultanță expertă pentru strategii digitale și management de proiect.',
      icon: <AssignmentIcon fontSize="large" sx={{color:'dodgerblue'}} />,
    },
        {
      title: 'Optimizare SEO',
      description: 'Creștem vizibilitatea site-ului tău în motoarele de căutare pentru a atrage mai mulți clienți potențiali.',
      icon: <SearchIcon fontSize="large" sx={{color:'dodgerblue'}} />,
    },
    {
      title: 'Design Grafic',
      description: 'Design original și responsiv pentru a atrage și reține vizitatorii pe toate dispozitivele.',
      icon: <GraphicDesignIcon fontSize="large" sx={{color:'dodgerblue'}} />,
    },

    {
      title: 'Securitate și Performanță',
      description: 'Asigurăm securitatea și performanța site-ului tău pentru o experiență online fără probleme.',
      icon: <SecurityIcon fontSize="large" sx={{color:'dodgerblue'}} />,
    },
    {
      title: 'Administrare Pagini',
      description: 'Platformă simplă și eficientă pentru administrarea conținutului site-ului tău web.',
      icon: <DashboardIcon fontSize="large" sx={{color:'dodgerblue'}} />,
    },
    {
      title: 'Monitorizare Trafic',
      description: 'Oferim module avansate pentru monitorizarea traficului și analiza performanței online.',
      icon: <BarChartIcon fontSize="large" sx={{color:'dodgerblue'}} />,
    },
    {
      title: 'Integrări Aplicații',
      description: 'Integrăm aplicații esențiale pentru a îmbunătăți interacțiunea și eficiența site-ului tău.',
      icon: <AppsIcon fontSize="large" sx={{color:'dodgerblue'}} />,
    },
    {
      title: 'API și Integrări Personalizate',
      description: 'Dezvoltăm și integrăm soluții bazate pe API pentru a extinde funcționalitățile platformei tale.',
      icon: <SettingsInputComponentIcon fontSize="large" sx={{color:'dodgerblue'}} />,
    },
    {
      title: 'Editare Conținut',
      description: 'Module avansate pentru gestionarea și editarea conținutului multimedia al site-ului tău.',
      icon: <EditIcon fontSize="large" sx={{color:'dodgerblue'}} />,
    },
    {
      title: 'Module Performanță',
      description: 'Optimizăm performanța și timpul de încărcare al paginilor tale pentru o experiență rapidă și plăcută.',
      icon: <SpeedIcon fontSize="large" sx={{color:'dodgerblue'}} />,
    },
    {
      title: 'Administrare Securizată',
      description: 'Acces securizat și management eficient al resurselor digitale pentru protecția și controlul complet al site-ului tău.',
      icon: <LockIcon fontSize="large" sx={{color:'dodgerblue'}} />,
    },
    {
      title: 'Salvare și Back-up',
      description: 'Soluții complete pentru back-up și recuperare a datelor pentru a-ți asigura continuitatea afacerii.',
      icon: <BackupIcon fontSize="large" sx={{color:'dodgerblue'}} />,
    },
  ];
  
  return (
 
    <motion.div
        variants={routeVariants}
        initial="initial"
        animate="final"
        className="acasa-component"
      >
        
    <Container id="acasa-section">

        <Box>
          <Box className="Sectiune" id='scroll-Acasa'>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <Box sx={{ mt: 4 }} className="TextConversieContainer" ref={textContainerRef}>
                  {loading ? (
                    <>
                      <Skeleton variant="text" width={isMobile ? 200 : 500} height={120} animation="wave" />

                      {isMobile ? (
                        <>
                          nopm<Skeleton variant="text" width={isMobile ? 300 : 700} height={60} animation="wave" sx={{ mt: 4 }} />
                          <Skeleton variant="text" width={isMobile ? 300 : 700} height={60} animation="wave" sx={{ mt: 2 }} />
                          <Skeleton variant="text" width={isMobile ? 300 : 700} height={60} animation="wave" sx={{ mt: 2 }} />
                          <Skeleton variant="text" width={isMobile ? 300 : 700} height={60} animation="wave" sx={{ mt: 2 }} />
                        </>
                      ) : (
                        <Skeleton variant="text" width={isMobile ? 300 : 700} height={250} animation="wave" sx={{ mt: 2 }} />
                      )}
                    </>
                  ) : (
                    <>
                  <Fade cascade damping={0.1}>
                  <Container sx={{display:'flex', justifyContent:'center'}}>
              <Fade cascade triggerOnce>
             <ChartIcon fontSize="large" sx={{color:'dodgerblue', fontSize:100}} />
             </Fade>
             </Container>
                      <Typography variant="h3" align="center">
                        Crește rata de conversie
                      </Typography>
              
           

                      <Typography variant="body1" sx={{ textAlign: isMobile ? 'left' :'center', mt: 3, fontSize: 20 }}>
                        Îmbunătățește eficiența afacerii tale și atrage mai mulți clienți cu ajutorul unei prezențe online remarcabile. Profită de avantajele unei strategii digitale bine puse la punct și vei vedea rezultatele în creșterea vânzărilor și fidelizarea clienților.
                      </Typography>
                      </Fade>
                    </>
                  )}
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} >
                <Box sx={{ mt: 4 }}>
                  <div id="grafic-section" className="ContainerGraficaConversie" >
                    <div className="GraficaContainerLiniiText">
                      {linii.map((linie, index) => (
                        <div key={index} className="caseta-linie">
                          <div
                            className="linie"
                            ref={el => liniiRef.current[index] = el!}
                            data-height={linie.inaltime}
                          >
                            <div className="procent">
                              {loading ? (
                                <Skeleton variant="text" width={60} height={30} animation="wave" />
                              ) : (
                                <>
                                  <span ref={el => procenteRef.current[index] = el!}>
                                    0
                                  </span>
                                  <span>%</span>
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </Box>
              </Grid>
            </Grid>
          </Box>


          <Box className="Sectiune" >
            <Grid container spacing={3} >
              <Grid item xs={12} sm={6} >
                <Box sx={{ mt: 4 }}>
                  <div id="animatieCarucior-section" className="ContainerGraficaConversie">
                    <AnimatieCarucior />
                  </div>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box sx={{ mt: 4 }} className="TextConversieContainer" ref={textContainerRef}>
                  {loading ? (
                    <>
                      <Skeleton variant="text" width={isMobile ? 200 : 500} height={120} animation="wave" />

                      {isMobile ? (
                        <>
                             <Fade cascade damping={0.1}>
                          <Skeleton variant="text" width={isMobile ? 300 : 700} height={60} animation="wave" sx={{ mt: 4 }} />
                          <Skeleton variant="text" width={isMobile ? 300 : 700} height={60} animation="wave" sx={{ mt: 2 }} />
                          <Skeleton variant="text" width={isMobile ? 300 : 700} height={60} animation="wave" sx={{ mt: 2 }} />
                          <Skeleton variant="text" width={isMobile ? 300 : 700} height={60} animation="wave" sx={{ mt: 2 }} />
                          </Fade>
                        </>
                      ) : (
                        <Skeleton variant="text" width={isMobile ? 300 : 700} height={250} animation="wave" sx={{ mt: 2 }} />
                      )}
                    </>
                  ) : (
                    <>
                      <Fade cascade damping={0.1}>
                      <Container sx={{display:'flex', justifyContent:'center'}}>
              <Fade cascade triggerOnce>
             <ConstructionIcon fontSize="large" sx={{color:'dodgerblue', fontSize:100}}/>
             </Fade>
             </Container>
                      <Typography variant="h3" align="center">
                        Construiește-ți Identitatea
                      </Typography>
             
                      <Typography variant="body1" sx={{ textAlign: isMobile ? 'left' :'center', mt: 3, fontSize: 20 }}>
                        De la conceptul inițial la lansarea efectivă, construirea unui site web personalizat pune în valoare unicitatea afacerii tale. Fiecare element este gândit pentru a reflecta identitatea și valorile tale, oferind o platformă eficientă și atrăgătoare pentru interacțiuni online de succes.
                      </Typography>
                      </Fade>
                    </>
                  )}
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Box className="Sectiune">
          <Grid container spacing={2}>
      <Grid item xs={12} sm={6} sx={{ mt: isMobile ? 20 : 4, mb: isMobile ? 20 : 4 }}>
        <Box sx={{ mt: 10 }} className="TextConversieContainer">
       
            <Fade>
                <Typography variant="h3" align="center">
                   Platforme de Success
                   </Typography>
            </Fade>
         

          <Container sx={{ display: 'flex', justifyContent: 'center', mr: isMobile ? 0:10  }}>
            <Fade cascade triggerOnce>
              <Star fontSize="large" sx={{ color: 'orange' }} />
              <Star fontSize="large" sx={{ color: 'orange' }} />
              <Star fontSize="large" sx={{ color: 'orange' }} />
              <Star fontSize="large" sx={{ color: 'orange' }} />
              <Star fontSize="large" sx={{ color: 'orange' }} />
            </Fade>
          </Container>
          <Fade>
          <Typography className="" variant="body2" align="center" sx={{ mt: 4, fontSize: 20}}>
       
              De la idee la realizare! Specializați în crearea de platforme ecommerce și în implementarea soluțiilor de management, transformăm conceptele tale în soluții digitale inovatoare și eficiente. Colaborăm pentru a construi o prezență online robustă și adaptată specificului afacerii tale.
          
          </Typography>
          </Fade>
        </Box>
      </Grid>

      <Grid item xs={12} sm={6} id="containerMareLoginDraw" sx={{ mt: isMobile ? 2 : 4, mb: isMobile ? 15 : 4 }}>
        <div className="ContainerSkeleton">
          <Fade cascade damping={0.1}>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Skeleton variant="circular" width={80} height={80} sx={{ mb: 5 }} />
              <Skeleton
                className="skeleton-login"
                variant="text"
                width={isMobile ? 170 : 600}
                sx={{ maxWidth: '98%', marginLeft: 5, mt: 0, height: 60, animation: 'wave' }}
              />
            </Box>
            <Skeleton className="skeleton-login" variant="text" width={isMobile ? 150 : 400} height={100} sx={{ maxWidth: '98%', mb: 2, ml: 6 }} animation="wave" />
            <Skeleton className="skeleton-login" variant="text" width={isMobile ? 380 : 700} sx={{ maxWidth: '98%' }} height={60} animation="wave" />
            <Skeleton className="skeleton-login" variant="text" width={isMobile ? 315 : 750} sx={{ maxWidth: '98%', ml: 1 }} height={60} animation="wave" />
            <Skeleton className="skeleton-login" variant="text" width={isMobile ? 330 : 330} sx={{ maxWidth: '98%' }} height={60} animation="wave" />
          </Fade>
        </div>
        <Box sx={{ mt: 4, ml: isMobile ? 'auto' : 20 }} className="LoginContainer" id="sectiune-login-container">
          <img src="imagini/avatar.svg" className="AvatarLoginDraw" alt="avatar IT VISION SOFTWARE" />
          <Typography variant="h5" align="center" gutterBottom>
            Conectare la Cont
          </Typography>
          <TextField
            sx={{ mt: 2, mb: 2, width: '80%' }}
            variant="outlined"
            id="usernameAnimatie"
            label="Nume utilizator"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <br />
          <TextField
            sx={{ mt: 2, mb: 2, width: '80%' }}
            variant="outlined"
            fullWidth
            id="passwordAnimatie"
            label="Parolă"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <Button sx={{ mt: 5 }} variant="contained" endIcon={<SendIcon />} onClick={handleLoginClick}>
            LOGIN
          </Button>
        </Box>
        {showFireworks && <FireworksComponent />}
      </Grid>
    </Grid>
  
    </Box>

        
        </Box>
    
    </Container>


                             
     <Container id="sectiune-carduri-prezentare" sx={{position:'relative',display:'flex', justifyContent:'center', alignItems: 'center', paddingTop: isMobile ? '50px' : '200px', paddingBottom: isMobile ? '50px' : '50px'}}>
      
      { isMobile ? <></> :
       <ParallaxComponent/>
      }
       <Container className="container-carduri" sx={{maxWidth:'100%', width:'100%'}}>
        <Fade cascade triggerOnce>
       <Typography align="center" variant="h4" sx={{mt:3}} >
                 
                 Multiple Posibilități, O Singură Echipă de Încredere
                            
                             </Typography>
          
                             <Typography align="center" variant="body1" sx={{mt:isMobile? 5: 1 ,mb:5}} >
                           
                             Lasă-ne pe noi să ne ocupăm de tot ce înseamnă web, pentru ca tu să te poți concentra pe ceea ce contează.
                                      
                                       </Typography>
                                       </Fade>
      <Grid container spacing={3}>
        {cardsData.map((card, index) => (
          <Grid item xs={12} sm={6} md={3} key={index}>
            <Fade cascade damping={1.5} triggerOnce>
            <Card className="card-prezentare">
           
              <div className="search-overlay-card-prezentare"></div>
              <Skeleton className="overlay-card-prezentare" variant="text" />
              <div className="bara-start-overlay-card-prezentare"></div>
              <div className="bulina-overlay-card-prezentare1"></div>
              <div className="bulina-overlay-card-prezentare2"></div>
              <div className="bulina-overlay-card-prezentare3"></div>
         
              <CardContent style={{ textAlign: 'center' }}>
                <div style={{ marginBottom: '1rem', alignSelf:'left' }}>{card.icon}</div>
                <Typography variant="h6" component="h2" gutterBottom>
                  {card.title}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {card.description}
                </Typography>
              </CardContent>
            </Card>
            </Fade>
          </Grid>
        ))}
      </Grid>
    </Container>



  
  <Box className="Sectiune" >


</Box>




</Container>

<Box id="acasa-section-slider" sx={{ mt: isMobile ? 5 : 25, mb: isMobile ? 5 : 25 }}>
      <Box>
        <Box className="Sectiune">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h3" align="center">
                <Fade triggerOnce>Tehnici Moderne</Fade>
              </Typography>
              <Fade cascade triggerOnce>
                <Typography align="center" variant="h6" sx={{ mt: 3, margin:1 }}>
                  Ne place să folosim cele mai bune practici și tehnici moderne pentru a ne asigura că oferim soluții de calitate.
                </Typography>
                <Modern fontSize="large" sx={{ color: 'dodgerblue', fontSize: 100 }} />
              </Fade>
            </Grid>
            <Grid item xs={12}>
              <ProgressiveSlider images={images} settings={settings} />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>

  

<Box id="sectiune-carduri-pasi" sx={{}}>



<Box className="Sectiune" id="SectiunePas1" sx={{maxWidth:'100%', width:'100%', borderRadius:'20px', zIndex:4}}>


<div className="linieSectiunePas1"></div>
<div style={{display:isMobile? 'none':'block'}} className="linieSectiuneSplitPas1"></div>
  <Grid container spacing={1} alignItems="center">
    {/* Left Section */}
    <Grid item xs={12} sm={6} sx={{ mt: isMobile ? 3: 4, mb: isMobile ? 3 : 10 }}>
      <Box sx={{ mt: 10}} className="TextConversieContainer" >
        <Typography variant="h3" align="center">
    
            <Container id="textpas1" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', fontWeight: 'bold' }}>
              <span>PASUL</span> <span className="NumarPasi">1</span>
            </Container>
            <Container id="subtextpas1" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', fontWeight: 'bold' }}>
              <Typography variant="h3" align="center">
                Consultanță și Analiză
              </Typography>
            </Container>
       
        </Typography>
      </Box>
    </Grid>

    {/* Right Section */}
    <Grid item xs={12} sm={6} id="ContainerTextPasi1" sx={{ mt: isMobile ? 3: 4, mb: isMobile ? 3 : 10 , background:'#f5f5f5'}}>
       
    <Box sx={{ display: 'flex', justifyContent:'center', alignItems: 'center' }}>
     
   
       <Fade cascade damping={0.4} style={{display:'flex', }}>
       <Container sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
  {/* First Typography */}
  <Typography variant='body1' align={isMobile ? 'left' : 'left'} sx={{paddingTop:5,paddingBottom:5, color: '#333333', fontWeight: 'normal', lineHeight: 1.6, margin: 'auto' }}>
    Primul lucru pe care îl facem este să ne întâlnim și să discutăm în detaliu despre visul tău pentru proiect. Vom nota tot ce este important pentru tine și vom stabili împreună pașii pe care trebuie să-i urmăm.
    <br /><br />
    <strong>Activități</strong>:
  </Typography>

  {/* Unordered List */}
  <ul style={{ paddingLeft: 20, listStyleType: 'none', margin: 0 }}>
    <li style={{ display: 'flex', alignItems: 'center', marginBottom: 8 }}>
      <Bifa fontSize="medium" sx={{ color: 'lightgreen', mr: 2 }} />Discuții despre idei și nevoi.
    </li>
    <li style={{ display: 'flex', alignItems: 'center', marginBottom: 8 }}>
      <Bifa fontSize="medium" sx={{ color: 'lightgreen', mr: 2 }} />Stabilirea obiectivelor clare ale proiectului.
    </li>
    <li style={{ display: 'flex', alignItems: 'center' }}>
      <Bifa fontSize="medium" sx={{ color: 'lightgreen', mr: 2 }} />Crearea unui plan detaliat pentru a ajunge la destinație.
    </li>
  </ul>

  {/* Second Typography */}
  <Typography variant='body1' align={isMobile ? 'left' : 'left'} sx={{paddingBottom:5, color: '#333333', fontWeight: 'normal', lineHeight: 1.6, margin: 'auto' }}>
    <strong>Rezultat</strong>: Un plan clar și bine definit pe care toată echipa îl înțelege și poate să-l urmeze.
  </Typography>
</Container>

          </Fade>
   
      </Box>
    </Grid>
  </Grid>
</Box>


<Box className="Sectiune" id="SectiunePas2" sx={{ zIndex:3}}>
<div className="linieSectiunePas2"></div>
<div style={{display:isMobile? 'none':'block'}} className="linieSectiuneSplitPas2"></div>

  <Grid container spacing={1} alignItems="center">
    {/* Left Section */}
    <Grid item xs={12} sm={6} sx={{ mt: isMobile ? 3: 4, mb: isMobile ? 3 : 10 }}>
      <Box sx={{ mt: 10 }} className="TextConversieContainer">
        <Typography variant="h3" align="center">
    
            <Container id="textpas2" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', fontWeight: 'bold' }}>
              <span>PASUL</span> <span className="NumarPasi">2</span>
            </Container>
            <Container id="subtextpas2" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', fontWeight: 'bold' }}>
              <Typography variant="h3" align="center">
              Construim Împreună
              </Typography>
            </Container>
  
        </Typography>
      </Box>
    </Grid>

    {/* Right Section */}
    <Grid item xs={12} sm={6} id="ContainerTextPasi2" sx={{ mt: isMobile ? 3: 4, mb: isMobile ? 3 : 10 , background:'#f5f5f5'}}>
       
    <Box sx={{ display: 'flex', justifyContent:'center', alignItems: 'center' }}>
     
   
       <Fade cascade damping={0.4} style={{display:'flex', }}>
       <Container sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
  {/* First Typography */}
  <Typography variant='body1' align={isMobile ? 'left' : 'left'} sx={{paddingTop:5,paddingBottom:5, color: '#333333', fontWeight: 'normal', lineHeight: 1.6, margin: 'auto' }}>
          Cu planul în mână, intrăm în acțiune! Echipa noastră de dezvoltatori și designeri începe să transforme ideile în realitate.            <br />
            <br />
            <strong>Activități</strong>:
            </Typography>
            <ul style={{ paddingLeft: 20, listStyleType:'none' }}>
        
                <li style={{display:'flex', justifyContent:'left', alignItems:'center'}}><Bifa fontSize="medium" sx={{color:'lightgreen', mr:2}}  />Crearea designului și interfeței utilizatorului.</li>
                <li style={{display:'flex', justifyContent:'left', alignItems:'center'}}><Bifa fontSize="medium" sx={{color:'lightgreen', mr:2}}  />Programarea și dezvoltarea fiecărei funcționalități</li>
                <li style={{display:'flex', justifyContent:'left', alignItems:'center'}}><Bifa fontSize="medium" sx={{color:'lightgreen', mr:2}}  />Testarea fiecărui element pentru a ne asigura că totul funcționează perfect.</li>
            </ul>
        
            <Typography  variant='body1' align={isMobile ? 'left' : 'left'} sx={{paddingBottom:5, color: '#333333', fontWeight: 'normal', lineHeight: 1.6, margin:'auto' }}>
            <strong>Rezultat</strong>: O aplicație sau un software funcțional, gata să fie testat și adus la viață.
          </Typography>
          </Container>
          </Fade>

      </Box>
    </Grid>
  </Grid>
</Box>


<Box className="Sectiune" id="SectiunePas3" sx={{maxWidth:'100%', borderRadius:'20px', zIndex:2, mt: 5}}>
  
<div className="linieSectiunePas3"></div>
<div style={{display:isMobile? 'none':'block'}} className="linieSectiuneSplitPas3"></div>

  <Grid container spacing={1} alignItems="center">
    {/* Left Section */}
    <Grid item xs={12} sm={6} sx={{ mt: isMobile ? 3: 4, mb: isMobile ? 3 : 10 }}>
      <Box sx={{ mt: 10 }} className="TextConversieContainer">
        <Typography variant="h3" align="center">
      
            <Container id="textpas3" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', fontWeight: 'bold' }}>
              <span>PASUL</span> <span className="NumarPasi">3</span>
            </Container>
            <Container id="subtextpas3" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', fontWeight: 'bold' }}>
              <Typography variant="h3" align="center">
             Perfecționarea Detaliilor
              </Typography>
            </Container>
        
        </Typography>
      </Box>
    </Grid>

    {/* Right Section */}
    <Grid item xs={12} sm={6} id="ContainerTextPasi3" sx={{ mt: isMobile ? 3: 4, mb: isMobile ? 3 : 10 , background:'#f5f5f5'}}>
       
    <Box sx={{ display: 'flex', justifyContent:'center', alignItems: 'center' }}>
     
   
       <Fade cascade damping={0.4} style={{display:'flex', }}>
       <Container sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
  {/* First Typography */}
  <Typography variant='body1' align={isMobile ? 'left' : 'left'} sx={{paddingTop:5,paddingBottom:5, color: '#333333', fontWeight: 'normal', lineHeight: 1.6, margin: 'auto' }}>
          După ce construcția este completă, trecem la verificarea fiecărui detaliu. Este momentul să ne asigurăm că totul este exact așa cum ai visat.         <br />
            <br />
            <strong>Activități</strong>:
            </Typography>
            <ul style={{ paddingLeft: 20, listStyleType:'none' }}>
        
                <li style={{display:'flex', justifyContent:'left', alignItems:'center'}}><Bifa fontSize="medium" sx={{color:'lightgreen', mr:2}}  />Testarea intensivă a aplicației pentru a depista orice problemă.</li>
                <li style={{display:'flex', justifyContent:'left', alignItems:'center'}}><Bifa fontSize="medium" sx={{color:'lightgreen', mr:2}}  />Corectarea și optimizarea fiecărui element.</li>
                <li style={{display:'flex', justifyContent:'left', alignItems:'center'}}><Bifa fontSize="medium" sx={{color:'lightgreen', mr:2}}  />Asigurarea că totul este pregătit pentru lansare.</li>
            </ul>
        
            <Typography variant='body1' align={isMobile ? 'left' : 'left'} sx={{paddingBottom:5, color: '#333333', fontWeight: 'normal', lineHeight: 1.6, margin:'auto' }}>

            <strong>Rezultat</strong>: Un produs finalizat și pregătit pentru a fi livrat clienților sau pentru a fi utilizat.
          </Typography>
          </Container>
          </Fade>

      </Box>
    </Grid>
  </Grid>
</Box>



<Box className="Sectiune" id="SectiunePas4" sx={{zIndex:4}}>
<div className="linieSectiunePas4"></div>
<div style={{display:isMobile? 'none':'block'}} className="linieSectiuneSplitPas4"></div>

  <Grid container spacing={1} alignItems="center">
    {/* Left Section */}
    <Grid item xs={12} sm={6} sx={{ mt: isMobile ? 3: 4, mb: isMobile ? 3 : 10 }}>
      <Box sx={{ mt: 10 }} className="TextConversieContainer">
        <Typography variant="h3" align="center">
        
            <Container id="textpas4"sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', fontWeight: 'bold' }}>
              <span>PASUL</span> <span className="NumarPasi">4</span>
            </Container>
            <Container id="subtextpas4" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', fontWeight: 'bold' }}>
              <Typography variant="h3" align="center">
              Lansarea Finală
              </Typography>
            </Container>
     
        </Typography>
      </Box>
    </Grid>

    {/* Right Section */}
    <Grid item xs={12} sm={6} id="ContainerTextPasi4" sx={{ mt: isMobile ? 3: 4, mb: isMobile ? 3 : 10 , background:'#f5f5f5'}}>
       
    <Box sx={{ display: 'flex', justifyContent:'center', alignItems: 'center' }}>
     
   
       <Fade cascade damping={0.4} style={{display:'flex', }}>
       <Container sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
  {/* First Typography */}
  <Typography variant='body1' align={isMobile ? 'left' : 'left'} sx={{paddingTop:5,paddingBottom:5, color: '#333333', fontWeight: 'normal', lineHeight: 1.6, margin: 'auto' }}>
          Ultimul pas este momentul cel mai așteptat: lansarea proiectului tău !          <br />Deși este ultima etapă a acestui proiect, este și primul pas spre o relație de colaborare durabilă și de încredere. <br />
            <strong>Activități</strong>:
            </Typography>
            <ul style={{ paddingLeft: 20, listStyleType:'none' }}>
        
                <li style={{display:'flex', justifyContent:'left', alignItems:'center'}}><Bifa fontSize="medium" sx={{color:'lightgreen', mr:2}}  />Asistență continuă pentru a te ajuta să îți începi activitatea.</li>
                <li style={{display:'flex', justifyContent:'left', alignItems:'center'}}><Bifa fontSize="medium" sx={{color:'lightgreen', mr:2}}  />Feedback și ajustări în funcție de nevoile și sugestiile tale.</li>
                <li style={{display:'flex', justifyContent:'left', alignItems:'center'}}><Bifa fontSize="medium" sx={{color:'lightgreen', mr:2}}  />Împărtășirea bucuriei succesului în realizarea acestui proiect minunat!</li>
            </ul>
        
            <Typography variant='body1' align={isMobile ? 'left' : 'left'} sx={{paddingBottom:5, color: '#333333', fontWeight: 'normal', lineHeight: 1.6, margin:'auto' }}>

            <strong>Rezultat</strong>: O experiență de succes pentru tine și beneficiarii tăi.
          </Typography>
          </Container>
          </Fade>

      </Box>
    </Grid>
  </Grid>
</Box>

</Box>
    </motion.div>
  );
};
export default Acasa;
