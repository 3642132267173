import React, { useState, useEffect, useRef } from 'react';
import { Container, Typography, Button, Box, styled, useTheme, useMediaQuery } from '@mui/material';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import WavingHandIcon from '@mui/icons-material/WavingHand';

import { useLocation } from 'react-router-dom';


gsap.registerPlugin(ScrollTrigger);

interface HeroSectionProps {
  videos: string[];
}

// Styled components
const BackgroundOverlay = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  background: 'linear-gradient(to bottom, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.8))',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  zIndex: 1,
  transition: 'opacity 1s ease-in-out',
  opacity: 0,
  '&.loaded': {
    opacity: 1,
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  marginBottom: theme.spacing(4),
  borderRadius: '50px',
  padding: theme.spacing(1, 5),
  fontSize: theme.typography.pxToRem(18),
  background: 'linear-gradient(to right, #1e3c72, #2a5298)',
  color: 'white',
  boxShadow: '0 4px 10px rgba(0, 0, 0, 0.3)',
  transition: 'transform 0.3s ease, background-color 0.3s ease',
  '&:hover': {
    transform: 'scale(1.05)',
    backgroundColor: '#3a5b9f',
  },
}));

const HeroSection: React.FC<HeroSectionProps> = ({ videos }) => {
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);
  const videoRefs = useRef<(HTMLVideoElement | null)[]>([]);
  const [typingText, setTypingText] = useState('');
  const textToType = 'xpertiză. Viziune. Rezultate.';
  const typingDelay = 100; // Delay between typing each character
  const deletingDelay = 50; // Delay between deleting each character
  const pauseDelay = 1500; // Pause between typing and deleting
  const [isDeleting, setIsDeleting] = useState(false);
  const [loopNum, setLoopNum] = useState(0);
  const [showArrow, setShowArrow] = useState(true);
  const theme = useTheme();
  const isSmallerScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [isLoaded, setIsLoaded] = useState(false);
  const [isTextVisible, setIsTextVisible] = useState(false);
  const [isButtonVisible, setIsButtonVisible] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const setupAnimations = () => {
      gsap.fromTo(
        '.start-button',
        { opacity: 1 },
        {
          y: -50,
          opacity: 0,
          duration: 1,
          ease: 'power3.out',
          scrollTrigger: {
            trigger: '.start-button',
            start: 'top 50%',
            toggleActions: 'play none none reverse',
          },
        }
      );

      gsap.fromTo(
        '.span-text-hero',
        { opacity: 1, filter: 'blur(0px)' },
        {
          opacity: 0,
          duration: 1,
          filter: 'blur(10px)',
          ease: 'power3.out',
          scrollTrigger: {
            trigger: '.span-text-hero',
            start: 'top 15%',
            toggleActions: 'play none none reverse',
          },
        }
      );

      gsap.fromTo(
        '.span-dezvoltare',
        { opacity: 1, x: 0, filter: 'blur(0px)' },
        {
          opacity: 0,
          x: -90,
          duration: 1,
          filter: 'blur(10px)',
          ease: 'power3.out',
          scrollTrigger: {
            trigger: '.span-dezvoltare',
            start: 'bottom 10%',
            toggleActions: 'play none none reverse',
          },
        }
      );
    };

    setupAnimations();
    ScrollTrigger.refresh(); // Ensure ScrollTrigger updates

    return () => {
      ScrollTrigger.getAll().forEach(trigger => trigger.kill());
    };
  }, [location]); // Reinitialize animations on route change

  useEffect(() => {
    const handleVideoEnded = () => {
      setIsVideoLoaded(false);
      setCurrentVideoIndex((prevIndex) => (prevIndex + 1) % videos.length);
    };

    videoRefs.current.forEach((video, index) => {
      if (video) {
        video.addEventListener('ended', handleVideoEnded);
      }
    });

    return () => {
      videoRefs.current.forEach((video, index) => {
        if (video) {
          video.removeEventListener('ended', handleVideoEnded);
        }
      });
    };
  }, [currentVideoIndex, videos]);

  useEffect(() => {
    const video = videoRefs.current[currentVideoIndex];
    if (video && !isVideoLoaded) {
      video.play().then(() => {
        setIsVideoLoaded(true);
      }).catch((error) => {
        console.error('Error playing video:', error);
        setIsVideoLoaded(false);
      });
    }
  }, [currentVideoIndex, isVideoLoaded]);

  useEffect(() => {
    let typingTimer: NodeJS.Timeout;

    const handleTyping = () => {
      if (!isDeleting && typingText.length < textToType.length) {
        setTypingText(textToType.substring(0, typingText.length + 1));
        typingTimer = setTimeout(handleTyping, typingDelay);
      } else if (!isDeleting && typingText.length === textToType.length) {
        setTimeout(() => setIsDeleting(true), pauseDelay);
      } else if (isDeleting && typingText.length > 0) {
        setTypingText(textToType.substring(0, typingText.length - 1));
        typingTimer = setTimeout(handleTyping, deletingDelay);
      } else if (isDeleting && typingText.length === 0) {
        setIsDeleting(false);
        setLoopNum(loopNum + 1);
        typingTimer = setTimeout(handleTyping, typingDelay);
      }
    };

    typingTimer = setTimeout(handleTyping, typingDelay);

    return () => {
      clearTimeout(typingTimer);
    };
  }, [typingText, isDeleting]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoaded(true);
    }, 500);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const textDelay = getRandomDelay();
    const buttonDelay = getRandomDelay();

    const textTimer = setTimeout(() => {
      setIsTextVisible(true);
    }, textDelay);

    const buttonTimer = setTimeout(() => {
      setIsButtonVisible(true);
    }, buttonDelay);

    return () => {
      clearTimeout(textTimer);
      clearTimeout(buttonTimer);
    };
  }, []);

  const getRandomDelay = () => {
    const minDelay = 500;
    const maxDelay = 1000;
    return Math.random() * (maxDelay - minDelay) + minDelay;
  };

  const handleScroll = () => {
    window.scrollBy({
      top: 700,
      behavior: 'smooth',
    });
  };

  const overlayClass = isLoaded ? "loaded" : "";
  const overlayStyle = {
    opacity: isLoaded ? 1 : 0,
    transition: "opacity 1s ease-in-out"
  };

  return (
    <Box id="herosectionID" position="relative" className="HeroSectionBox" width="100%" height="100vh" overflow="hidden">
      {videos.map((video, index) => (
        <video
          key={index}
          ref={(element) => (videoRefs.current[index] = element)}
          autoPlay
          muted
          loop={false}
          style={{
            opacity: index === currentVideoIndex ? 1 : 0,
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            position: 'absolute',
            top: 0,
            left: 0,
            transition: 'opacity 1s ease-in-out',
          }}
        >
          <source src={video} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      ))}
      <BackgroundOverlay className={overlayClass} style={overlayStyle}>
        <Container maxWidth="md">
          <Typography
            variant={isSmallerScreen ? 'h3' : 'h2'}
            component="h1"
            color="white"
            gutterBottom
            style={{
              marginTop: isSmallerScreen ? 70 : 40,
              color: '#C0C0C0',
              transition: 'opacity 1s ease-in-out',
              opacity: isTextVisible ? 1 : 0,
              fontFamily: '"Roboto", sans-serif', // Add modern font
              fontWeight: 700,
            }}
          >
            <span className="span-text-hero">Partenerii tăi în</span> <span className="span-dezvoltare">dezvoltarea </span><span className="span-text-hero">digitală</span>
          </Typography>
          <Typography
            variant={isSmallerScreen ? 'h6' : 'h5'}
            component="h2"
            color="white"
            className="span-text-hero"
            gutterBottom
            style={{
              marginTop: isSmallerScreen ? 70 : 40,
              color: '#C0C0C0',
              transition: 'opacity 1s ease-in-out',
              opacity: isTextVisible ? 1 : 0,
              fontFamily: '"Roboto", sans-serif',
              fontWeight: 400,
            }}
          >
            E{typingText}
          </Typography>
          <StyledButton
            variant="contained"
            color="primary"
            className="start-button"
            style={{
              position: 'absolute',
              bottom: isSmallerScreen ? 80 : 20,
              left: '50%',
              transform: 'translateX(-50%)',
              opacity: isButtonVisible ? 1 : 0,
              fontSize: isSmallerScreen ? 12 : 15,
            }}
            onClick={handleScroll}
          >
            <WavingHandIcon />
          </StyledButton>
        </Container>
      </BackgroundOverlay>
    </Box>
  );
};

export default HeroSection;
