import React, { useState } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Skeleton } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { useAuth } from '../contexts/AuthContext'; // Import useAuth

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const defaultTheme = createTheme();

interface SignInSideProps {
  open: boolean;
  onClose: () => void;
}

const SignInSide: React.FC<SignInSideProps> = ({ open, onClose }) => {
  const { userName, login, logout } = useAuth();
  const [loading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error'>('success');

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);
  
    const email = (event.currentTarget.elements.namedItem('email') as HTMLInputElement).value;
    const password = (event.currentTarget.elements.namedItem('password') as HTMLInputElement).value;
  
    try {
      await login(email, password);
      setSnackbarMessage('Autentificare reușită!');
      setSnackbarSeverity('success');
    } catch (error: any) {
      console.error('Login error:', error);
      setSnackbarMessage(error.message || 'Eroare de autentificare. Verificați emailul și parola.');
      setSnackbarSeverity('error');
    } finally {
      setLoading(false);
      setSnackbarOpen(true);
    }
  };

  const handleLogout = async () => {
    try {
      await logout();
      setSnackbarMessage('Delogare reușită!');
      setSnackbarSeverity('success');
    } catch (error) {
      console.error('Logout error:', error); // Log the error
      setSnackbarMessage('Eroare la delogare.');
      setSnackbarSeverity('error');
    } finally {
      setSnackbarOpen(true);
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <CssBaseline />
      <Drawer
        anchor="right"
        open={open}
        onClose={onClose}
        sx={{
          '& .MuiDrawer-paper': {
            width: '100%',
            maxWidth: 400,
            height: '100%',
            padding: 2,
            boxSizing: 'border-box',
            zIndex: 1200,
          },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            height: '100%',
            justifyContent: 'center',
            position: 'relative',
          }}
        >
          <IconButton
            edge="start"
            color="inherit"
            onClick={onClose}
            sx={{
              position: 'absolute',
              top: 40,
              right: 16,
              zIndex: 1300,
            }}
          >
            <CloseIcon />
          </IconButton>
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            {userName ? `Salut, ${userName}` : 'Autentificare'}
          </Typography>
          {loading ? (
            <Box sx={{ mt: 2, width: '100%' }}>
              <Skeleton variant="circular" width={40} height={40} sx={{ mb: 2 }} />
              <Skeleton variant="text" width={200} height={40} sx={{ mb: 2 }} />
              <Skeleton variant="rectangular" width="100%" height={60} sx={{ mb: 2 }} />
              <Skeleton variant="rectangular" width="100%" height={60} sx={{ mb: 2 }} />
              <Skeleton variant="rectangular" width="100%" height={50} />
            </Box>
          ) : (
            <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1, width: '100%' }}>
              {!userName ? (
                <>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Adresă email"
                    name="email"
                    autoComplete="email"
                    autoFocus
                  />
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="Parolă"
                    type="password"
                    id="password"
                    autoComplete="current-password"
                  />
                  <FormControlLabel
                    control={<Checkbox value="remember" color="primary" />}
                    label="Ține-mă minte"
                  />
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                  >
                    Autentificare
                  </Button>
                </>
              ) : (
                <Button
                  type="button"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                  onClick={handleLogout}
                >
                  Delogare
                </Button>
              )}
              <Box sx={{ flexGrow: 1 }} />
              <Box>
                <Link href="#" variant="body2">
                  {/* Other links */}
                </Link>
              </Box>
              <Box>
                <Link href="#" variant="body2">
                  {/* Other links */}
                </Link>
              </Box>
            </Box>
          )}
        </Box>
      </Drawer>

      {/* Snackbar for notifications */}
      <Snackbar
        open={snackbarOpen}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </ThemeProvider>
  );
};

export default SignInSide;
